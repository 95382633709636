<template>
  <div class="ShippingAddress">
    <Header/>
    <div class="shipa-top-space-line">&nbsp;</div>
    <div class="ship-a-wrap">
      <div class="wrap-left">
        <div class="step-t-fir">
          <span class="proc-fir">1</span>
          <span class="step-t-word">{{'收货地址' | formatTrans}}</span>
        </div>
        <div class="re-word-stf">{{'我们应该送到哪里?' | formatTrans}}</div>
        <div class="sin-info-wrap">
          <div
            class="addr-info-wrap"
            v-for="(item,index) in addressLists"
            :key="index"
            @click="handleRadioChange(item,index + 1)"
          >
            <div class="left">
              <div class="radio-wrap" :class="radioVal == (index + 1) ? 'rad-active' : ''">
                <div class="radio-point" v-show="radioVal == (index + 1)">&nbsp;</div>
              </div>
            </div>
            <div class="right">
              <div class="first-line" v-if="item.userAddressDefault == 1">{{'默认地址' | formatTrans}}</div>
              <div class="first-line" v-else>{{'地址' | formatTrans}}</div>
              <div
                class="sec-line"
              >{{ item.firstName | formatTrans}} {{ item.lastName | formatTrans}}，{{ item.zipCode | formatTrans}}，{{ item.userAddressPhone }}</div>
              <div
                class="third-line"
              >{{ item.userAddressAddress | formatTrans}} {{ item.userAddressArea | formatTrans}}</div>
            </div>
          </div>

          <div class="stepS">
            <div class="first-line" @click="selectRad">
              <div class="left">
                <div class="radio-wrap" :class="radioVal == 0 ? 'rad-active' : ''">
                  <div class="radio-point" v-show="radioVal == 0">&nbsp;</div>
                </div>
              </div>
              <div class="right">{{'新增地址' | formatTrans}}</div>
            </div>
            <div v-show="radioVal == 0" class="addr-form-wrap">
              <el-form :model="addressForm" :rules="rules" ref="addressForm" class="addressForm">
                <el-form-item prop="firstName">
                  {{'名' | formatTrans}}
                  <el-input type="text" v-model="addressForm.firstName" clearable placeholder></el-input>
                </el-form-item>
                <el-form-item prop="lastName">
                  {{'姓' | formatTrans}}
                  <el-input type="text" v-model="addressForm.lastName" clearable placeholder></el-input>
                </el-form-item>
                <el-form-item prop="Region">
                  <div style="display: flex; flex-direction: column">
                    <label>{{'国家/地区' | formatTrans}}</label>
                    <el-select
                      filterable
                      v-model="addressForm.country"
                      placeholder="please check country"
                      @change="countryChange"
                    >
                      <el-option
                        v-for="dict in country_list"
                        :key="dict.value"
                        :label="dict.name_en"
                        :value="dict.name_en"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item prop="userAddressArea">
                  {{'州或省/市/区' | formatTrans}}
                  <el-input type="text" v-model="addressForm.userAddressArea" clearable placeholder></el-input>
                </el-form-item>
                <el-form-item prop="userAddressAddress">
                  {{'详细地址' | formatTrans}}
                  <el-input
                    type="text"
                    v-model="addressForm.userAddressAddress"
                    clearable
                    placeholder
                  ></el-input>
                </el-form-item>
                <el-form-item prop="zipCode">
                  {{'邮政编码' | formatTrans}}
                  <el-input type="text" v-model="addressForm.zipCode" clearable placeholder></el-input>
                </el-form-item>
                <el-form-item prop="userAddressPhone">
                  {{'手机号码' | formatTrans}}
                  <el-input
                    type="text"
                    v-model="addressForm.userAddressPhone"
                    clearable
                    placeholder
                  ></el-input>
                </el-form-item>
                <div class="footerBtn">
                  <el-button round @click="CancelFn()" class="cancelbutton">{{'取消'| formatTrans}}</el-button>
                  <el-button
                    type="primary"
                    round
                    @click="submitForm()"
                    class="button"
                  >{{'保存'| formatTrans}}</el-button>
                </div>
              </el-form>
            </div>
          </div>

          <div class="btns">
            <el-tooltip
              :manual="!isDisabled"
              class="item"
              effect="dark"
              content="请先选择收货地址"
              placement="top"
            >
              <el-button
                :disabled="isDisabled"
                @click="ContinueToPackaging"
                style="width:30%;"
                type="primary"
                round
                class="button"
              >{{'继续下单' | formatTrans}}</el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="stepN">
          <div class="step-t-sec">
            <span class="proc-sec">2</span>
            <span class="proc-sec-word">{{'付款' | formatTrans}}</span>
          </div>
          <div class="re-word-sts">PayPal, Sand Pay</div>
        </div>
      </div>

      <div class="orderOverview">
        <div class="texc">{{ '订单汇总' | formatTrans }}({{ goodsInfo.length }})</div>
        <div v-for="(item,index) in goodsInfo" :key="index" class="sin-goods-wrap">
          <div class="goods-img">
            <img :src="item.goodsImage">
          </div>
          <div class="goods-info-wrap">
            <div class="goods-name">{{ item.goodsName }}</div>

            <div class="sec-line">
              <div class="goods-price">{{ curSymbol }}{{ item.goodsPrice }}</div>
              <div class="goods-num">{{'QTY' | formatTrans}}: {{item.goodsNum}}</div>
            </div>

            <div class="third-line">
              <span
                v-for="(itemF,indexF) in item.specification"
                :key="indexF"
                class="sin-guige-wrap"
              >
                <template v-if="itemF.paramName == 'Colour'">
                  <span>{{ itemF.paramName | formatTrans }}:</span>
                  <el-tooltip :content="itemF.paramValue" placement="top">
                    <span class="color-img">
                      <img :src="itemF.paramImage">
                    </span>
                  </el-tooltip>
                </template>
                <template v-else>{{ itemF.paramName }}: {{ itemF.paramValue }}</template>
              </span>
            </div>
          </div>
        </div>

        <div class="total-sta">
          <div class="item-tot">
            <div>{{'数量' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ goodsTotPri }}</div>
          </div>

          <div class="item-tot">
            <div>{{'运费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ freiVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'税费' | formatTrans}}</div>
            <div>{{ curSymbol }}{{ taxVal }}</div>
          </div>

          <div class="item-tot">
            <div>{{'小计' | formatTrans}}:</div>
            <div class="sub-price">{{ curSymbol }}{{ subVal }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="shipa-bot-space-line">&nbsp;</div>
    <Footer/>
  </div>
</template>
<script>
import { getAddress, addAddress, updateAddress, logout } from "@/api/index";
import { getTrans } from "@/api/set.js";
import AddAddress from "@/components/AddressBook/AddAddress.vue";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import country from "@/utils/country.json";
const originList = country;
export default {
  components: {
    AddAddress,
    Header,
    Footer
  },
  data() {
    return {
      traversed: false, // 标识是否已经遍历过
      country_list: originList,
      isDisabled: true,
      hasAddress: false, //是否有地址
      active: 0,
      selectedValue: "",
      radioVal: -1,
      addNew: "",
      addressForm: {},
      addressLists: [],
      addressListsdatas: {},
      rules: {},
      goodsInfo: [],
      pageType: 1,
      goodsTotPri: 0,
      freiVal: 0,
      taxVal: 0,
      subVal: 0,
      curSymbol: ""
    };
  },

  mounted() {
    this.getAddressList();
    try {
      this.curSymbol = sessionStorage.getItem("currencySymbol");
      this.goodsInfo = JSON.parse(sessionStorage.getItem("goodsListsdatas"));
      this.getTotalData();
    } catch (error) {}
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  methods: {
    // 国家改变
    countryChange(value) {
      this.addressForm.country = value;
    },

    // 默认地址变化
    defChange(event) {
      if (event == true) {
        this.addressForm.userAddressDefault = 1;
      } else {
        this.addressForm.userAddressDefault = 0;
      }
    },

    // 取消
    CancelFn() {
      this.radioVal = -1;
      this.$refs.addressForm.resetFields();
      this.$set(this.addressForm, "country", "");
    },

    // 保存地址
    submitForm() {
      let data = {
        token: sessionStorage.getItem("token"),
        firstName: this.addressForm.firstName,
        lastName: this.addressForm.lastName,
        country: this.addressForm.country,
        userAddressDefault: this.addressForm.userAddressDefault,
        userAddressPhone: this.addressForm.userAddressPhone,
        userAddressAddress: this.addressForm.userAddressAddress,
        zipCode: this.addressForm.zipCode,
        userAddressArea: this.addressForm.userAddressArea
      };
      this.add(data);
    },

    // 添加操作
    add(data) {
      addAddress(data).then(res => {
        if (res.code == 1) {
          this.$message({
            message: getTrans("Success"),
            type: "success",
            duration: 3000,
            customClass: "auto-mes-box"
          });

          this.getAddressList();
          this.radioVal = -1;
          this.$refs.addressForm.resetFields();
          this.$set(this.addressForm, "country", "");
        } else if (res.code == 10016) {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },

    // 更新操作
    update(data) {
      updateAddress(data).then(res => {
        if (res.code == 1) {
          this.getAddressList();
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box"
          });
        }
      });
    },

    handleRadioChange(value, index) {
      this.radioVal = index;
      this.addressListsdatas = value;
      this.isDisabled = false;
      // 在这里处理点击事件
    },

    selectRad() {
      this.radioVal = 0;
    },

    // 获取地址列表
    getAddressList() {
      let data = {
        token: sessionStorage.getItem("token")
      };

      getAddress(data).then(res => {
        if (res.code == 1 && res.data) {
          if (res.data.length > 0) {
            this.hasAddress = true;
          }
          this.addressLists = res.data;
        } else if (res.code == 10016) {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box"
          });
        }
      });
    },

    getTotalData() {
      let totPri = 0;
      for (let i = 0; i < this.goodsInfo.length; i++) {
        totPri += this.goodsInfo[i].goodsPrice * this.goodsInfo[i].goodsNum;
      }

      this.goodsTotPri = totPri;
      this.subVal = totPri;
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    increment() {
      this.quantity += 1; // 增加数量
    },

    decrement() {
      if (this.quantity > 1) {
        this.quantity -= 1; // 减少数量
      }
    },

    // save Address
    ContinueToPackaging() {
      sessionStorage.setItem(
        "addressListsdatas",
        JSON.stringify(this.addressListsdatas)
      );

      this.$router.replace({
        path: "/Payment"
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.ShippingAddress {
  .shipa-top-space-line {
    height: 80px;
  }

  .shipa-bot-space-line {
    height: 100px;
  }

  .ship-a-wrap {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 50px;

    .wrap-left {
      width: 870px;

      .step-t-fir {
        font-size: 26px;
        font-weight: bold;

        .proc-fir {
          width: 26px;
          height: 26px;
          display: inline-block;
          text-align: center;
          line-height: 26px;
          border: 2px solid rgba(0, 0, 0, 0.85);
          border-radius: 50%;
        }

        .step-t-word {
          margin-left: 12px;
        }
      }

      .re-word-stf {
        margin-left: 40px;
        margin-top: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
      }

      .sin-info-wrap {
        margin-top: 28px;

        .addr-info-wrap {
          display: flex;
          padding: 20px 24px;
          border: 1px solid #ccc;
          border-radius: 10px;
          cursor: pointer;
          margin-top: 12px;

          .left {
            .radio-wrap {
              width: 16px;
              height: 16px;
              border: 1px solid rgba(0, 0, 0, 0.3);
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 50%;

              .radio-point {
                width: 6px;
                height: 6px;
                background-color: rgba(185, 132, 101, 1);
                border-radius: 50%;
              }
            }

            .rad-active {
              border: 1px solid rgba(185, 132, 101, 1);
            }
          }

          .right {
            margin-left: 12px;

            .first-line {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              font-weight: 550;
            }

            .sec-line,
            .third-line {
              color: rgba(0, 0, 0, 0.85);
              font-size: 12px;
            }

            .sec-line {
              margin-top: 12px;
            }

            .third-line {
              margin-top: 6px;
            }
          }
        }

        .addr-info-wrap:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .stepS {
    border: 1px solid #ccc;
    border-radius: 10px;
    margin: 10px 0;
    cursor: pointer;

    .first-line {
      display: flex;
      padding-top: 20px;
      padding-bottom: 20px;
      padding-left: 24px;

      .radio-wrap {
        width: 16px;
        height: 16px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        .radio-point {
          width: 6px;
          height: 6px;
          background-color: rgba(185, 132, 101, 1);
          border-radius: 50%;
        }
      }

      .rad-active {
        border: 1px solid rgba(185, 132, 101, 1);
      }

      .right {
        margin-left: 12px;
      }
    }

    .addr-form-wrap {
      width: 60%;
      margin: 0 auto;
      margin-top: 24px;
      padding-left: 24px;
      padding-bottom: 20px;
    }

    .footerBtn {
      display: flex;
      justify-content: space-around;
      justify-items: center;

      .cancelbutton {
        color: #2c3e50;
        background-color: #fff;
        border-color: #ccc;
        font-size: 18px !important;
        margin-top: 20px;
      }

      .button {
        color: #fff;
        background-color: #b98465;
        border-color: #b98465;
        font-size: 18px !important;
        margin-top: 20px;
      }
    }
  }

  .btns {
    .button {
      color: #fff;
      background-color: #b98465;
      border-color: #b98465;
      font-size: 18px !important;
      margin-top: 28px;
    }
  }

  .stepN {
    border-radius: 10px;
    padding: 20px 24px;
    margin-top: 48px;
    background: rgba(243, 243, 245, 1);

    .step-t-sec {
      font-size: 26px;
      font-weight: bold;
      opacity: 0.45;

      .proc-sec {
        width: 26px;
        height: 26px;
        border: 2px solid rgba(0, 0, 0, 0.85);
        display: inline-block;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
      }

      .proc-sec-word {
        margin-left: 12px;
      }
    }

    .re-word-sts {
      margin-left: 40px;
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      opacity: 0.45;
    }
  }

  .orderOverview {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    width: 300px;

    .texc {
      margin-top: 24px;
      margin-bottom: 24px;
    }

    .sin-goods-wrap {
      width: 252px;
      margin: 0 auto;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      padding: 24px 0 20px 0;
      display: flex;

      .goods-img {
        width: 70px;
        height: 70px;
        background: rgba(243, 243, 245, 1);
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 80%;
        }
      }

      .goods-info-wrap {
        width: 170px;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .goods-name {
          color: rgba(0, 0, 0, 0.85);
          font-size: 14px;
          font-weight: 550;
        }

        .sec-line {
          margin-top: 4px;
          display: flex;
          justify-content: space-between;

          .goods-price {
            color: rgba(127, 73, 42, 1);
            font-size: 14px;
          }

          .goods-num {
            color: rgba(0, 0, 0, 0.85);
            font-size: 12px;
          }
        }

        .third-line {
          display: flex;
          justify-content: space-between;
          font-size: 12px;

          .sin-guige-wrap {
            display: flex;
            align-items: center;

            .color-img {
              width: 33px;
              height: 20px;
              background: rgba(243, 243, 245, 1);
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 4px;

              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }

    .total-sta {
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      padding: 20px 0 20px 0;
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      width: 252px;
      margin: 0 auto;

      .item-tot {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
      }

      .item-tot:nth-child(1) {
        margin-top: 0;
      }

      .sub-price {
        color: rgba(127, 73, 42, 1);
        font-weight: bold;
      }
    }
  }

  ::v-deep .el-form-item__label {
    line-height: 60px;
  }

  ::v-deep .el-input {
    position: relative;
    font-size: 14px;
  }
}
</style>

