<template>
  <div class="OrderHistory">
    <Header/>
    <div class="ordh-top-space-line">&nbsp;</div>
    <div class="order-h-wrap">
      <div class="titles">
        <div class="titleBig">{{ '历史订单' | formatTrans }}</div>
      </div>
      <div class="line">
        <img src="@/assets/images/login/login.png">
      </div>

      <div v-if="!hasOrderHistory" class="addressImg">
        <div class="tab-wrap">
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 0 ? 'tab-sel-active' : ''"
            v-if="!hasOrderHistory"
            @click="selectTab(0)"
          >
            <div class="title">{{ '所有订单' | formatTrans }}</div>
          </div>
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 1 ? 'tab-sel-active' : ''"
            @click="selectTab(1)"
          >
            <div class="title">{{ '待支付' | formatTrans }}</div>
          </div>
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 2 ? 'tab-sel-active' : ''"
            @click="selectTab(2)"
          >
            <div class="title">{{ '待发货' | formatTrans }}</div>
          </div>
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 3 ? 'tab-sel-active' : ''"
            @click="selectTab(3)"
          >
            <div class="title">{{ '待收货' | formatTrans }}</div>
          </div>
        </div>

        <div class="tab-pane-con">
          <div class="remind-fir">{{ '您的订单将显示在此处。' | formatTrans }}</div>
          <div>{{ '一旦你下了订单，你就可以跟随它的每一步。' | formatTrans }}</div>
        </div>

        <div class="go-sh-btn">
          <el-button type="primary" round @click="goShopping" class="btn">{{'继续购物' | formatTrans}}</el-button>
        </div>
      </div>

      <div class="orderHistoryList" v-show="hasOrderHistory">
        <div class="tab-wrap">
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 0 ? 'tab-sel-active' : ''"
            v-if="hasOrderHistory"
            @click="selectTab(0)"
          >
            <div class="title">{{ '全部订单' | formatTrans }}</div>
          </div>
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 1 ? 'tab-sel-active' : ''"
            @click="selectTab(1)"
          >
            <div class="title">{{ '待付款' | formatTrans }}</div>
          </div>
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 2 ? 'tab-sel-active' : ''"
            @click="selectTab(2)"
          >
            <div class="title">{{ '待发货' | formatTrans }}</div>
          </div>
          <div
            class="tab-pane-wrap"
            :class="selTabIndex == 3 ? 'tab-sel-active' : ''"
            @click="selectTab(3)"
          >
            <div class="title">{{ '待收货' | formatTrans }}</div>
          </div>
        </div>

        <div class="tab-pane-con">
          <div v-if="!hasOrderHistory" class="no-data-word">
            <div class="remind-fir">{{ '您的订单将显示在此处。' | formatTrans }}</div>
            <div>{{ '一旦你下了订单，你就可以跟随它的每一步。' | formatTrans }}</div>
          </div>
          <div class="order-table-wrap">
            <div class="order-table-m" v-for="(item,index) in orderHistoryLists" :key="index">
              <div class="table-title">
                <div class="left">{{ '订单编号' | formatTrans }} {{ item.orderId }}：</div>
                <div class="right">{{ '下单时间' | formatTrans }} {{ item.orderCreateTime }}：</div>
              </div>
              <div class="order-table-content">
                <div class="order-t-item-wrap">
                  <div
                    class="order-table-in"
                    v-for="(itemF,indexF) in item.goodsDetailsList"
                    :key="indexF"
                  >
                    <div class="order-table-item">
                      <div class="goods-img">
                        <img :src="itemF.goodsImage" alt>
                      </div>
                      <div class="goods-info">
                        <div class="goods-name">{{ itemF.goodsName }}</div>
                        <div class="goods-attr">
                          <div v-for="(itemS,indexS) in itemF.specValueVOs" :key="indexS">
                            <template v-if="itemS.paramName == 'Colour'">
                              <span>{{ itemS.paramName | formatTrans }}：</span>
                              <el-tooltip :content="itemS.paramValue" placement="top">
                                <span class="img-wrap">
                                  <img :src="itemF.goodsImage" alt>
                                </span>
                              </el-tooltip>
                            </template>
                            <template v-if="itemS.paramName != 'Colour'">
                              <span>{{ itemS.paramName | formatTrans }}：</span>
                              <span>{{ itemS.paramValue | formatTrans }}</span>
                            </template>
                          </div>
                          <div>
                            <span>{{ '数量' | formatTrans }}：</span>
                            <span>{{ itemF.orderGoodsNum }}</span>
                          </div>
                        </div>
                        <div class="goods-price">{{ curSymbol }}{{ itemF.goodsPrice }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order-t-info">
                  <div class="left">
                    <div class="fir-item">
                      <span class="sub-title">SubTotal</span>
                      <span class="sub-val">{{ curSymbol }}{{ item.orderTotalPrice }}</span>
                    </div>
                    <div class="sec-item">
                      <span class="freight">Freight: {{ curSymbol }}0</span>
                      <span class="tax-val">Tax: {{ curSymbol }}0</span>
                    </div>
                  </div>
                  <div class="right">
                    <div class="goods-status" v-if="item.orderStatus == 1">{{ '待付款' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 3"
                    >{{ '等待接收' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 4"
                    >{{ '等待接收' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 6"
                    >{{ '确认收货' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 7"
                    >{{ '取消' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 8"
                    >{{ '退款中' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 9"
                    >{{ '已退款' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 10"
                    >{{ '待自提' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 12"
                    >{{ '虚拟订单已过期' | formatTrans }}</div>
                    <div
                      class="goods-status"
                      v-else-if="item.orderStatus == 16"
                    >{{ '部分发货' | formatTrans }}</div>
                    <!-- <u class="detail-btn" @click="goOrderDetails(item)">{{ 'Order Details'  }}</u> -->
                    <u class="detail-btn" @click="goOrderDetails(item)">{{'支付' | formatTrans}}</u>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ordh-bot-space-line">&nbsp;</div>
    <Footer/>
  </div>
</template>
<script>
import { queryOrdersByPC, logout } from "@/api/index";
import { getTrans } from "@/api/set.js";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "OrderHistory",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      selTabIndex: 0,
      hasOrderHistory: "", //是否有地址
      orderHistoryLists: [],
      orderStatusT: 1,
      curSymbol: ""
    };
  },

  created() {
    this.curSymbol = sessionStorage.getItem('currencySymbol')
    this.fetchData();
  },

  activated() {
    this.curSymbol = sessionStorage.getItem('currencySymbol')
  },

  methods: {
    selectTab(index) {
      this.selTabIndex = index;

      if (index == 0) {
        this.orderStatusT = 1;
        this.fetchData();
      } else if (index == 1) {
        //待付款
        this.orderStatusT = 1;
        this.fetchData();
      } else if (index == 2) {
        //待发货
        this.orderStatusT = 3;
        this.fetchData();
      } else if (index == 3) {
        //待收货
        this.orderStatusT = 6;
        this.fetchData();
      }
    },

    goShopping() {
      this.$router.push({
        path: "/ClassificationDescribe",
        query: { clType: 3 }
      });
    },

    goOrderDetails(val) {
      const url =
        "https://www.hdy33.com/pc/pages/hdypay.html?paymentnumber=" +
        val.paymentNumber +
        "&arg=" +
        sessionStorage.getItem("token") +
        "&opay=" +
        val.orderTotalPrice +
        "&orderDetail={" +
        "token:" +
        sessionStorage.getItem("token") +
        "}";

      window.location.href = url;

      this.fetchData();

      // this.$router.push({ name: "OrderDetails", params: val });
    },

    // 获取历史订单
    fetchData() {
      let obj = {
        token: sessionStorage.getItem("token"),
        limit: 10,
        orderNo: "",
        orderStatus: this.orderStatusT,
        page: 1,
        timeEnd: "",
        timeStart: "",
        virtualGoods: 1
      };
      queryOrdersByPC(obj).then(res => {
        if (res.code == 1 && res.data) {
          if (res.data.page.list.length > 0) {
            this.hasOrderHistory = true;
          } else {
            this.hasOrderHistory = false;
          }

          this.orderHistoryLists = res.data.page.list;
        } else if (res.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.hasOrderHistory = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.OrderHistory {
  height: 100vh;

  ::v-deep .el-tabs__item {
    font-size: 24px;
  }
  .ordh-top-space-line {
    height: 80px;
  }

  .ordh-bot-space-line {
    height: 100px;
  }

  .order-h-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;

    .titles {
      text-align: center;

      .titleBig {
        font-size: 40px;
        color: #333;
        font-weight: 900;
      }
    }

    .line {
      width: 972px;
      margin: 50px auto;

      img {
        width: 100%;
      }
    }
  }

  .addressImg {
    margin: 50px auto;
    width: 1200px;
    text-align: center;

    .tab-wrap {
      width: 640px;
      margin: 0 auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.45);
      display: flex;
      cursor: pointer;

      .tab-pane-wrap {
        width: 160px;
        padding-bottom: 8px;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
        font-size: 24px;
      }

      .tab-sel-active {
        border-bottom: 3px solid rgba(185, 132, 101, 1);
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .tab-pane-con {
      margin-top: 100px;

      .remind-fir {
        margin-bottom: 20px;
        font-size: 26px;
      }
    }

    .go-sh-btn {
      width: 220px;
      height: 48px;
      margin: 0 auto;
      margin-top: 48px;

      .btn {
        width: 100%;
        height: 100%;
        border-radius: 29px;
      }
    }
  }

  .orderHistoryList {
    margin-top: 49px;

    .tab-wrap {
      width: 640px;
      margin: 0 auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.45);
      display: flex;
      cursor: pointer;

      .tab-pane-wrap {
        width: 160px;
        padding-bottom: 8px;
        text-align: center;
        color: rgba(0, 0, 0, 0.45);
        font-size: 24px;
      }

      .tab-sel-active {
        border-bottom: 3px solid rgba(185, 132, 101, 1);
        color: rgba(0, 0, 0, 0.85);
      }
    }

    .tab-pane-con {
      width: 1200px;
      margin: 0 auto;
      margin-top: 49px;

      .no-data-word {
        margin-top: 100px;
        text-align: center;

        .remind-fir {
          margin-bottom: 20px;
          font-size: 26px;
        }
      }
    }

    .order-table-wrap {
      .order-table-m {
        border-radius: 5px;
        border: 1px solid #ccc;
        margin-top: 24px;
      }

      .table-title {
        background: rgba(243, 243, 245, 1);
        display: flex;
        justify-content: space-between;
        padding: 10px 24px;
        color: rgba(0, 0, 0, 0.85);
        font-size: 12px;
        border-top-left-radius: 5px; /* 左上角圆角 */
        border-top-right-radius: 5px; /* 右上角圆角 */
      }

      .order-table-content {
        display: flex;
        justify-content: space-between;

        .order-t-item-wrap {
          width: 884px;
        }

        .order-table-in {
          border-top: 1px solid #ccc;
        }

        .order-table-in:nth-child(1) {
          border-top: none;
        }

        .order-table-item {
          width: 100%;
          display: flex;
          padding: 24px 0;
          border-top: 1px solid #ccc;

          .goods-img {
            width: 110px;
            height: 110px;
            background: rgba(243, 243, 245, 1);
            border-radius: 5px;
            margin-left: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              width: 90%;
            }
          }

          .goods-info {
            margin-left: 13px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 2px 0;

            .goods-name {
              color: rgba(0, 0, 0, 0.85);
              font-size: 18px;
              font-weight: 550;
            }

            .goods-attr {
              display: flex;
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;

              div:nth-child(n) {
                margin-left: 49px;
              }

              div:nth-child(1) {
                display: flex;
                align-items: center;
                margin-left: 0;

                .img-wrap {
                  width: 40px;
                  height: 24px;
                  background: rgba(243, 243, 245, 1);

                  img {
                    width: 100%;
                    height: 100%;
                  }
                }
              }
            }

            .goods-price {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
            }
          }
        }

        .order-table-item:nth-child(1) {
          border-top: none;
        }

        .order-t-info {
          border-left: 1px solid #ccc;
          display: flex;
          justify-content: center;

          .left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 144px;

            .fir-item {
              display: flex;
              flex-direction: column;
              text-align: center;

              .sub-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
              }

              .sub-val {
                color: rgba(127, 73, 42, 1);
                font-size: 18px;
                margin-top: 8px;
              }
            }

            .sec-item {
              margin: 0 auto;
              margin-top: 12px;
              background: rgba(243, 243, 245, 1);
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: rgba(0, 0, 0, 0.55);
              font-size: 12px;
              text-align: center;
              width: 96px;
              height: 52px;

              .tax-val {
                margin-top: 4px;
              }
            }
          }

          .right {
            width: 170px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            border-left: 1px solid #ccc;

            .goods-status {
              padding: 3px 4px;
              border-radius: 2px;
              background-color: rgba(185, 132, 101, 0.15);
              color: rgba(185, 132, 101, 1);
              font-size: 12px;
              margin: 0 auto;
            }

            .detail-btn {
              color: rgba(0, 0, 0, 0.85);
              font-size: 16px;
              margin-top: 17px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>

