import defaultrequest from '@/utils/default_request'
import enData from '@/public/i18n/i18n_en.json'
import cnData from '@/public/i18n/i18n_cn.json'

const nodeEnv = process.env.NODE_ENV

export function getCurrentTrans() {
  var language
  if (nodeEnv == 'development') {
    language = sessionStorage.getItem('pcLanguage') || 'cn';
  } else {
    language = sessionStorage.getItem('pcLanguage') || 'en';
  }
  
  return defaultrequest({
    url: '/public/i18n/i18n_' + language + '.json',
    method: 'get',
  })
}

export function getTrans(src) {

  var pcLanguage
  if (nodeEnv == 'development') {
    pcLanguage = sessionStorage.getItem('pcLanguage') || 'cn';
    
    if (!sessionStorage.getItem("i18nLang")) {
      sessionStorage.setItem("i18nLang", JSON.stringify(cnData))
    }
  } else {
    pcLanguage = sessionStorage.getItem('pcLanguage') || 'en'

    if (!sessionStorage.getItem("i18nLang")) {
      sessionStorage.setItem("i18nLang", JSON.stringify(enData))
    }
  }

  var i18nLang = JSON.parse(sessionStorage.getItem('i18nLang'));

  if(!src){
    return src;
  }

  if (pcLanguage == "cn") {
    return src;
  }

  if (i18nLang.hasOwnProperty(src)) {
    return i18nLang[src];
  }

  var params = {
    src: src,
    to: pcLanguage
  }

  var hostUrl
  if (nodeEnv == 'development') {
    hostUrl = "https://www.hdy33.com"
  } else if (nodeEnv == 'staging') {
    hostUrl = "http://192.168.1.233:30889"
  } else {
    hostUrl = "https://www.hdy33.com"
  }

  var url = hostUrl + '/mall-third-party/baidu/trans';
  var xhr = new XMLHttpRequest();
  xhr.open('POST', url, false); // 同步请求
  xhr.setRequestHeader('Content-type', 'application/json');
  xhr.send(JSON.stringify(params));
  if (xhr.status === 200) {
    i18nLang[src] = JSON.parse(xhr.responseText).data.dst;
    sessionStorage.setItem("i18nLang", JSON.stringify(i18nLang));
    return JSON.parse(xhr.responseText).data.dst;
  } else {
    return src
    console.error('请求失败');
  }
  i18nLang[src] = JSON.parse(xhr.responseText).data.dst;
  sessionStorage.setItem("i18nLang", JSON.stringify(i18nLang));
}

