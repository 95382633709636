import request from "@/utils/request";
// 获取货币
export function getcurSymbol() {
  return request({
    url: "/mall-base/base/currencySymbol/open",
    method: "get"
  });
}

//登录 mall-auth-server/user/email/login
export function login(params) {
  return request({
    url: "/mall-auth-server/user/email/login",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 发送邮箱验证码
export function sendEmailCode(params) {
  return request({
    url: "/mall-userms/user/ms/email/forget/send",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 校检邮箱验证码
export function verifyEmailCode(params) {
  return request({
    url: "/mall-userms/user/ms/email/forget/judge",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 邮箱重置密码
export function emailResetPas(params) {
  return request({
    url: "/mall-userms/user/ms/email/forget/reset",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 登录后重置密码
export function loginResetPas(params) {
  return request({
    url: "/mall-userms/user/ms/email/updatePassword",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

//退出登录
export function logout(params) {
  return request({
    url: "/mall-userms/user/ms/userLogOut",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

//注册 /mall-userms/user/ms/email/register
export function register(params) {
  return request({
    url: "/mall-userms/user/ms/email/register",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 发送注册验证码
export function sendRegisCode(params) {
  return request({
    url: "/mall-userms/user/ms/email/register/send",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

//获取用户信息 /mall-userms/user/ms/getuser
export function getUser(params) {
  return request({
    url: "/mall-userms/user/ms/getuser",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 首页搜索
export function listFilter(params) {
  return request({
    url: "/mall-business/shopGoods/listFilter",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 心愿清单 mall-userms/user/favoritesGoods/get
export function favoritesGoods(params) {
  return request({
    url: "/mall-userms/user/favoritesGoods/get",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 获取历史订单 mall-userms/user/favoritesGoods/get
export function queryOrdersByPC(params) {
  return request({
    url: "/mall-order/order/base/queryOrdersByPC",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 获取地址列表 mall-userms/user/address/get
export function getAddress(params) {
  return request({
    url: "/mall-userms/user/address/get",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 购物车列表 /mall-order/order/userCart/queryShoppingCart
export function queryShoppingCart(params) {
  return request({
    url: "/mall-order/order/userCart/queryShoppingCart",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 加入心愿单 /mall-userms//user/favoritesGoods/add
export function addfavoritesGoods(params) {
  return request({
    url: "/mall-userms//user/favoritesGoods/add",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// mall-userms/user/favoritesGoods/check 查询商品是否收藏
export function favoritesGoodsCheck(params) {
  return request({
    url: "/mall-userms/user/favoritesGoods/check",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 获取品牌店铺 mall-business/shopBrand/info/query
export function getshopBrand(params) {
  return request({
    url: "/mall-business/shopBrand/info/query",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 获取品牌店铺详情二类 mall-business/shopBase/info/query/shopCats
export function getShopCats(params) {
  return request({
    url: "/mall-business/shopBase/info/query/shopCats",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 获取精选系列 mall-business/shopSeries/info/query
export function getshopSeries(params) {
  return request({
    url: "/mall-business/shopSeries/info/query",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 获取商品详情 mall-goods/goods/common/query/detail
export function getgoodsdetail(params) {
  return request({
    url: "/mall-goods/goods/common/query/detail",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 提交订单 mall-order/order/appCreateOrders
export function appCreateOrders(params) {
  return request({
    url: "/mall-order/order/appCreateOrders",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 设置默认址 mall-userms/user/address/setDefault
export function setDefault(params) {
  return request({
    url: "/mall-userms/user/address/setDefault",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 删除地址 /mall-userms/user/address/delete
export function deleteAddress(params) {
  return request({
    url: "/mall-userms/user/address/delete",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// mall-userms/user/address/v2/add
export function addAddress(params) {
  return request({
    url: "/mall-userms/user/address/v2/add",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// /user/address/v2/update
export function updateAddress(params) {
  return request({
    url: "/mall-userms/user/address/v2/update",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// /mall-base/base/shopHomeTemplate/user/get
export function getIndex(params) {
  return request({
    url: "/mall-base/base/shopHomeTemplate/user/get",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// /mall-business/shopGoods/listFilterByGoodsIds
export function listFilterByGoodsIds(params) {
  return request({
    url: "/mall-business/shopGoods/listFilterByGoodsIds",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 加入购物车 mall-order/order/userCart/insertShoppingCart
export function insertShoppingCart(params) {
  return request({
    url: "/mall-order/order/userCart/insertShoppingCart",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 加入购物车 /mall-business/shopBase/info/query/shopCats
export function getShopCatsS(params) {
  return request({
    url: "/mall-business/shopBase/info/query/shopCats",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 购物车删除 mall-order/order/userCart/batchDeleteGoods
export function batchDeleteGoods(params) {
  return request({
    url: "/mall-order/order/userCart/batchDeleteGoods",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 删除收藏 mall-userms/user/favoritesGoods/delete
export function deletefavoritesGoods(params) {
  return request({
    url: "/mall-userms/user/favoritesGoods/delete",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 选择商品规格 /mall-goods/goods/common/spec/queryNew
export function selectGoodsAttrs(params) {
  return request({
    url: "/mall-goods/goods/common/spec/queryNew",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 计算价格 /mall-order/order/calculatePrice
export function calculatePrice(params) {
  return request({
    url: "/mall-order/order/calculatePrice",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 邮箱激活 mall-userms/user/ms/email/active
export function emailActive(params) {
  return request({
    url: "mall-userms/user/ms/email/active",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}

// 确认更改规格 /mall-order/order/userCart/changeGoodsSpec
export function changeGoodsSpec(params) {
  return request({
    url: "mall-order/order/userCart/changeGoodsSpec",
    headers: {
      isToken: true
    },
    method: "post",
    data: params
  });
}
