<template>
  <!-- 商品分类详情 -->
  <!-- <div>{{this.$route.query.id}}</div> -->
  <div class="ProDescWrap">
    <Header/>
    <div class="pro-t-space-line">&nbsp;</div>
    <div class="productsDesc" v-if="detailData">
      <div class="left-img-wrap">
        <div v-show="!flag" class="left-img" v-html="detailData.goodsCommonBody"></div>
        <div v-show="flag" v-for="(item,index) in selectGuiGedetailData" :key="index">
          <img class="wscnph" :src="item.imagesImage">
        </div>
      </div>

      <div class="goods-info">
        <div class="title">
          {{ detailData.goodsName }}
          <!-- {{ isFavorited ? '未收藏' : '已收藏' }} -->
          <img :src="this.isFavorited ? collect : collectAcctive" @click="toggleFavorite">
        </div>
        <div class="price">
          <del>{{ curSymbol }}{{ detailData.goodsMarketPrice }}</del>
          <span class="cur-price">{{ curSymbol }}{{ detailData.goodsPrice }}</span>
          <div class="disc-val" v-show="detailData.label == '1' || detailData.label == '3'">
            <!-- 优惠率=（原价-特惠价）/原价*100% -->
            <span class="disc-num">{{detailData.discountPercent}}%</span>
            <span class="disc-word">OFF</span>
          </div>
          <div class="disc-val-new" v-show="detailData.label == '2' || detailData.label == '3'">NEW</div>
        </div>

        <!-- <div v-for="(item,index) in guigeData" :key="index">
          <div class="gui-ge-title">{{ item.paramName | formatTrans}}</div>
          <ul class="gui-ge">
            <li
              :class="item.selected == items ? 'gui-ge-selected': ''"
              v-for="(items,indexs) in item.paramNameValues"
              :key="indexs"
              @click="selectGuiGe(item.paramName,items)"
            >{{items | formatTrans}}</li>
          </ul>
        </div>-->
        <div v-for="(item,indexT) in guigeData" :key="indexT">
          <div class="gui-ge-title">{{ item.paramName | formatTrans}}</div>
          <ul class="gui-ge" v-show="item.paramName == 'Size'">
            <li
              :class="item.selected == items ? 'gui-ge-selected': ''"
              v-for="(items,indexE) in item.paramNameValues"
              :key="indexE"
              @click="selectGuiGe(item.paramName,items,'',2)"
            >{{items | formatTrans}}</li>
          </ul>
          <ul class="gui-ge-colour" v-show="item.paramName == 'Colour'">
            <li
              :class="item.selected == items.Colour ? 'gui-ge-selected': ''"
              v-for="(items,indexF) in item.paramNameValuesActivity"
              :key="indexF"
              @click="selectGuiGe(item.paramName,items.Colour,items.paramImage,1)"
            >
              <el-tooltip :content="items.Colour" placement="top">
                <img :src="items.paramImage" style="width:100%;">
              </el-tooltip>
            </li>
          </ul>
        </div>

        <br>
        <div class="clearb"></div>

        <div class="quantity">
          <span class="fontS">{{'数量' | formatTrans}}</span>
          <span class="quantityBtn">
            <div class="decrement fl" type @click="decrement">-</div>
            <span class="fl">{{ quantity }}</span>
            <div class="decrement fl" type @click="increment">+</div>
          </span>
        </div>
        <div class="btns">
          <el-button
            @click="addToCart"
            style="width:100%;"
            type="primary"
            round
            class="shopNow"
          >Add to cart</el-button>
        </div>
        <div class="btns">
          <el-button
            style="width:100%;"
            type="primary"
            round
            class="button"
            @click="GoToCheckout"
          >Shop now</el-button>
        </div>
        <div class="contact-us" @click="skipContUs">
          <img :src="contFrame" alt>
          <span>{{'联系我们' | formatTrans}}</span>
        </div>
        <div class="description">
          <div class="des-title">{{'详细说明' | formatTrans}}</div>
          <p>{{detailData.goodsCommonDesc | formatTrans}}</p>
        </div>
        <!-- 加入购物车右侧弹框 -->
        <div class="cart" v-show="dialogVisible">
          <div class="cartTitle">
            <div style="padding-left:6px;">{{'购物车' | formatTrans}} ({{numberOfValidProducts}})</div>
            <div class="closeCart" @click="closeCart">
              <img src="@/assets/images/close.png">
            </div>
          </div>
          <div class="clearb"></div>
          <!-- <div class="lineS" style="margin-top: 20px;"></div> -->
          <div>
            <div class="fr Subtotal">
              {{'小计' | formatTrans}}:
              <span>{{ curSymbol }}{{ totalAmount ? totalAmount : 0 }}</span>
            </div>
            <el-table :data="cartLists" style="width: 100%;">
              <el-table-column label width>
                <template slot-scope="scope">
                  <el-table
                    ref="multipleTable"
                    :data="scope.row.goodsDetailsList"
                    style="width: 100%"
                    :height="heightNew"
                    fit
                    @selection-change="handleSelectionChange"
                  >
                    <el-table-column type="selection" width="45" label="Select All"></el-table-column>
                    <!-- <el-table-column type="selection" width="30" label="Select All"></el-table-column> -->
                    <el-table-column prop="goodsImage" label="Select All" width="100">
                      <template slot-scope="scope">
                        <img class="goodsImage" :src="scope.row.goodsImage">
                      </template>
                    </el-table-column>
                    <el-table-column prop="goodsImage" label>
                      <template slot-scope="scope">
                        <span class="goodsName">{{scope.row.goodsName}}</span>
                        <div>
                          <div class="fl goodsPrice">{{ curSymbol }}{{scope.row.goodsPrice}}</div>
                          <div class="fr QTY">QTY:{{scope.row.goodsNum}}</div>
                        </div>
                        <div class="clearb"></div>
                        <div>
                          <ul class="gdsCommon fl">
                            <li
                              v-show="item.paramName == 'Colour'"
                              v-for="(item,indexC) in scope.row.specification"
                              :key="indexC+11"
                            >
                              <span style>{{item.paramName | formatTrans}}:&nbsp;</span>
                              <img :src="item.paramImage" style>
                            </li>
                            <li
                              v-show="item.paramName == 'Size'"
                              v-for="(item,indexD) in scope.row.specification"
                              :key="indexD+21"
                            >{{item.paramName | formatTrans}}: {{item.paramValue | formatTrans}}</li>
                          </ul>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- <div class="lineS" style="margin-top: 20px;"></div> -->
                  <!-- <div class="fr Subtotal">
                    Subtotal:
                    <span>${{ totalAmount ? totalAmount : 0 }}</span>
                  </div>-->
                  <!-- <div>
                    <div class="fl SelectAll">
                      <el-checkbox
                        @change="toggleSelection(scope.row.goodsDetailsList)"
                        v-model="checked"
                      >{{'Select All' | formatTrans}}</el-checkbox>
                    </div>
                  </div>-->
                  <div class="clearb"></div>
                  <div class="lineS"></div>
                </template>
              </el-table-column>
            </el-table>
          </div>

          <div class="clearb"></div>

          <div class="btns">
            <el-tooltip
              :manual="!isDisabled"
              class="item"
              effect="dark"
              :content="dynamicContent"
              placement="top"
            >
              <el-button
                :disabled="isDisabled"
                style="width:100%; margin-top:40px;"
                type="primary"
                round
                class="button"
                @click="cartGoToCheckout"
              >{{'前往结账' | formatTrans}}</el-button>
            </el-tooltip>
          </div>
          <div class="btns">
            <el-button
              @click="viewCart"
              style="width:100%;"
              type="primary"
              round
              class="shopNow"
            >{{'查看购物车' | formatTrans}}</el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title="Tips"
      :visible.sync="centerDialogVisible"
      width="20%"
      :before-close="handleClose"
    >
      <span>{{'Successfully added to shopping cart' | formatTrans}}</span>
      <span
        slot="footer"
        class="dialog-footer"
        style="display:flex; flex-direction:row;justify-content: center;"
      >
        <el-button class="btn" type="primary" @click="goToCartList">{{'go to cart' | formatTrans}}</el-button>
      </span>
    </el-dialog>
    <div class="pro-b-space-line">&nbsp;</div>
    <div class="footer">
      <!-- 在这里放置底部的内容 -->
      <div class="box">
        <div class="copyRight">
          <div
            class="copyRightContent"
          >{{'版权所有 © 2021-2024 保留所有权利.技术支持：江苏鹤都云人工智能科技有限公司' | formatTrans}}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getgoodsdetail,
  insertShoppingCart,
  favoritesGoodsCheck,
  addfavoritesGoods,
  deletefavoritesGoods,
  selectGoodsAttrs,
  calculatePrice,
  queryShoppingCart,
  logout
} from "@/api/index";
import { getTrans } from "@/api/set.js";
import collect from "@/assets/images/collect.png";
import collectAcctive from "@/assets/images/collectAcctive.png";
import contFrame from "@/assets/images/contact-frame.png";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      defaultSelection: {},
      flagS: false,
      dynamicContent: "Please select the product first",
      totalAmount: 0,
      isDisabled: true,
      heightNew: 0,
      arrData: [],
      numberOfValidProducts: 0, // 购物车的商品数量
      cartLists: [],
      checked: false, // 是否全选
      flag: false, // 是否是第一次打开详情页面
      selectGuiGedetailData: [], // 切换规格时
      collect,
      collectAcctive,
      contFrame,
      isFavorited: false, // 收藏状态
      goodsId: 1,
      centerDialogVisible: false,
      detailData: null, // 详情,
      commonId: "",
      guigeData: [],
      dialogVisible: false,
      quantity: 1, // 初始购物车数量
      cartform: {},
      goodsListsdatas: [],
      processedJsonString: [],
      paramNameValuesT: [],
      curSymbol: ""
    };
  },

  created() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
    this.goodsId = this.$route.query.goodsId;
    this.getProductsDetails(this.$route.query.goodsId);
    // if (sessionStorage.getItem("token")) {
    //   this.getFavoritesGoodsCheck();
    // }
  },

  activated() {
    this.curSymbol = sessionStorage.getItem("currencySymbol");
  },

  computed: {},
  mounted() {
    this.heightNew = `calc(100vh - 350px)`; //小轮播图的高度
  },

  methods: {
    selFirst() {
      this.$nextTick(() => {
        // 选中第一条数据
        this.$refs.multipleTable.toggleRowSelection(this.defaultSelection);
      });
    },
    toggleFavorite() {
      if (sessionStorage.getItem("token")) {
        if (this.isFavorited) {
          this.addFN(this.detailData);
        } else {
          this.deleteFn(this.detailData);
        }
      } else {
        this.$message({
          message: getTrans("Please sign in first."),
          type: "warning",
          customClass: "auto-mes-box"
        });
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    // 选择商品规格
    selectGuiGe(param1, param2, param3, param4) {
      let newObj = {};
      for (let i = 0; i < this.guigeData.length; i++) {
        if (this.guigeData[i].paramName == param1) {
          this.$set(this.guigeData[i], "selected", param2);
          this.$forceUpdate();
        }
      }

      let specData = [];

      for (let i = 0; i < this.guigeData.length; i++) {
        specData.push({
          paramName: this.guigeData[i].paramName,
          paramValue: this.guigeData[i].selected
        });
      }

      this.quantity = 1;

      let data = {
        commonId: this.commonId,
        specValues: specData
      };

      selectGoodsAttrs(data).then(response => {
        if (response.code == 1) {
          this.flag = true;
          this.goodsId = response.data.goodsId;
          // this.detailData = response.data;
          this.$set(this.detailData, "goodsPrice", response.data.goodsPrice);
          this.$set(
            this.detailData,
            "goodsMarketPrice",
            response.data.goodsMarketPrice
          );
          this.$set(this.detailData, "goodsId", response.data.goodsId);
          this.$set(this.detailData, "goodsImage", response.data.goodsImage);

          if (param4 == 1) {
            for (let i = 0; i < this.detailData.specification.length; i++) {
              if (this.detailData.specification[i].paramName == "Colour") {
                this.$set(this.detailData.specification, i, {
                  paramName: this.guigeData[i].paramName,
                  paramValue: param2,
                  paramImage: param3
                });
              }
            }
          } else {
            for (let i = 0; i < this.detailData.specification.length; i++) {
              if (this.detailData.specification[i].paramName != "Colour") {
                this.$set(this.detailData.specification, i, {
                  paramName: this.guigeData[i].paramName,
                  paramValue: param2
                });
              }
            }
          }

          this.selectGuiGedetailData = response.data.goodsImages;
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
          this.flag = false;
        }
      });
    },

    addFN(val) {
      if (sessionStorage.getItem("token")) {
        let data = {
          commonId: val.commonId,
          goodsId: val.goodsId,
          token: sessionStorage.getItem("token")
        };
        addfavoritesGoods(data).then(response => {
          if (response.code == 1) {
            this.$message({
              message: getTrans("success"),
              type: "success",
              customClass: "auto-mes-box",
              duration: 3000
            });
            this.isFavorited = false;
          } else if (res.code == 10016) {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              customClass: "auto-mes-box",
              duration: 3000
            });

            logout({ token: sessionStorage.getItem("token") }).then(res => {
              if (res.code == 1) {
                sessionStorage.removeItem("token");
                localStorage.removeItem("emailId");
                this.$router.replace("/Login");
              }
            });
          } else {
            this.$message({
              message: getTrans(response.message),
              type: "error",
              customClass: "auto-mes-box",
              duration: 3000
            });
          }
        });
      } else {
        this.$message({
          message: getTrans("Please login first"),
          type: "error",
          customClass: "auto-mes-box",
          duration: 3000
        });
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    deleteFn(val) {
      let data = {
        commonIds: [val.commonId.toString()],
        token: sessionStorage.getItem("token")
      };

      deletefavoritesGoods(data).then(response => {
        if (response.code == 1) {
          this.isFavorited = true;
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans("Cancel collection"),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
    },

    // 获取首页商品详细信息
    getProductsDetails(id) {
      let data = {
        goodsId: id
      };

      getgoodsdetail(data).then(res => {
        if (res.code == 1 && res.data) {
          this.detailData = res.data.goodsBases;

          this.commonId = res.data.gdsCommon.commonId;
          this.guigeData = res.data.gdsCommon.specParamList;
          // this.guigeData = this.specParamList;

          let specData = res.data.goodsBases.specValueList;

          for (let i = 0; i < this.guigeData.length; i++) {
            this.guigeData[i].selected = specData[i].paramValue;
          }

          this.$set(this.detailData, "specification", []);

          for (let i = 0; i < this.guigeData.length; i++) {
            if (this.guigeData[i].paramName == "Colour") {
              this.$set(this.detailData.specification, i, {
                paramName: this.guigeData[i].paramName,
                paramValue: this.guigeData[i].paramNameValues[0],
                paramImage: this.guigeData[i].paramNameValuesActivity[0]
                  .paramImage
              });
            } else {
              this.$set(this.detailData.specification, i, {
                paramName: this.guigeData[i].paramName,
                paramValue: this.guigeData[i].paramNameValues[0]
              });
            }
          }

          if (sessionStorage.getItem("token")) {
            this.getFavoritesGoodsCheck();
          }
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });
        }
      });
    },

    // 查询商品是否收藏
    getFavoritesGoodsCheck() {
      let obj = {
        token: sessionStorage.getItem("token"),
        commonId: this.commonId
      };
      favoritesGoodsCheck(obj).then(res => {
        if (res.code == 1 && res.message == "未收藏") {
          this.isFavorited = true;
          console.log("res====>", res);
        } else if (res.code == 1 && res.message == "已收藏") {
          this.isFavorited = false;
        } else if (res.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        }
      });
    },

    // 加入购物车
    addToCart() {
      // this.dialogVisible = true;
      if (sessionStorage.getItem("token")) {
        let data = {
          token: sessionStorage.getItem("token"),
          goodsId: this.goodsId,
          goodsNum: this.quantity
        };
        insertShoppingCart(data).then(res => {
          if (res.code == 1 && res.data) {
            this.dialogVisible = true;
            this.getCartList();
            // this.centerDialogVisible = true;
          } else if (res.code == 10016) {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              customClass: "auto-mes-box",
              duration: 3000
            });

            logout({ token: sessionStorage.getItem("token") }).then(res => {
              if (res.code == 1) {
                sessionStorage.removeItem("token");
                localStorage.removeItem("emailId");
                this.$router.replace("/Login");
              }
            });
          } else {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              customClass: "auto-mes-box",
              duration: 3000
            });
          }
        });
      } else {
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    // 购物车列表
    getCartList() {
      let data = {
        token: sessionStorage.getItem("token")
      };
      queryShoppingCart(data).then(response => {
        if (response.code == 1 && response.data.list) {
          this.numberOfValidProducts = response.data.numberOfValidProducts;
          this.cartLists = response.data.list;
          this.defaultSelection = response.data.list[0].goodsDetailsList[0];
          this.selFirst();
        } else if (response.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(response.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },

    // 立即购买
    GoToCheckout() {
      if (sessionStorage.getItem("token")) {
        let data = {
          redPacketId: 0,
          orderReceiverProvinceId: 0,
          orderReceiverCityId: 0, //市id
          orderReceiverAreaId: 0, //区id
          platForm: 1,
          token: sessionStorage.getItem("token"),
          storeInformationList: [
            {
              shopId: this.detailData.shopId,
              couponId: 0,
              goodsBaseList: [
                {
                  goodsId: this.detailData.goodsId,
                  commonId: this.detailData.commonId,
                  goodsNum: this.quantity
                }
              ]
            }
          ]
        };

        calculatePrice(data).then(res => {
          if (res.code == 1 && res.data) {
            this.$set(this.detailData, "goodsTotalPrice", res.data.totalPrice);
            this.$set(
              this.detailData,
              "goodsNum",
              res.data.shippingList[0].goodsNo
            );

            let new_arr = [];
            new_arr.push(this.detailData);

            sessionStorage.setItem("goodsListsdatas", JSON.stringify(new_arr));

            this.$router.replace({
              path: "/ShippingAddress"
            });
          } else if (res.code == 10016) {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              customClass: "auto-mes-box",
              duration: 3000
            });

            logout({ token: sessionStorage.getItem("token") }).then(res => {
              if (res.code == 1) {
                sessionStorage.removeItem("token");
                localStorage.removeItem("emailId");
                this.$router.replace("/Login");
              }
            });
          } else {
            this.$message({
              message: getTrans(res.message),
              type: "error",
              customClass: "auto-mes-box",
              duration: 3000
            });
          }
        });
      } else {
        if (this.$route.path !== "/Login") {
          this.$router.replace("/Login");
        }
      }
    },

    // Go To Checkout
    cartGoToCheckout() {
      sessionStorage.setItem("goodsListsdatas", JSON.stringify(this.arrData));

      this.$router.replace({
        path: "/ShippingAddress"
      });
    },

    // 跳转联系我们
    skipContUs() {
      this.$router.push("/ContactUs");
    },

    // 去购物车
    goToCartList() {
      this.$router.push("/ViewCart");
    },

    handleClose() {
      this.centerDialogVisible = false;
    },

    openDialog() {
      this.dialogVisible = true;
    },

    closeCart() {
      this.dialogVisible = false;
    },

    handleSelectionChange(val) {
      if (val != "") {
        this.isDisabled = false;
        this.totalAmount = 0;
        val.forEach(row => {
          // this.$refs.multipleTable.toggleRowSelection(row);
          this.totalAmount += row.goodsNum * row.goodsPrice;
        });
      } else {
        this.isDisabled = true;
        this.totalAmount = 0;
      }

      this.multipleSelection = val;
      this.arrData = val;
    },

    // // 手动单选某些商品
    // handleSelectionChange(rows) {
    //   debugger;
    //   // if(rows.length == ){}
    //   if (rows != "" && !this.flagS) {
    //     this.isDisabled = false;
    //     this.totalAmount = 0;
    //     rows.forEach(row => {
    //       // this.$refs.multipleTable.toggleRowSelection(row);
    //       this.totalAmount += row.goodsNum * row.goodsPrice;
    //     });
    //     // this.totalAmount += row.goodsNum * row.goodsPrice;
    //   } else if (rows == "" && !this.flagS) {
    //     this.isDisabled = true;
    //     this.totalAmount = 0;
    //   }

    //   this.multipleSelection = rows;
    //   this.arrData = rows;
    // },

    // 全选/全不选
    // toggleSelection(rows) {
    //   debugger;
    //   // this.totalAmount = 0;
    //   this.flagS = !this.flagS;
    //   if (rows && this.flagS) {
    //     // this.flagS = false;
    //     this.isDisabled = false;
    //     this.totalAmount = 0;
    //     rows.forEach(row => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //       this.totalAmount += row.goodsNum * row.goodsPrice;
    //     });
    //     this.arrData = rows;
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //     this.totalAmount = 0;
    //     // this.flagS = true;
    //     // this.isDisabled = true;
    //   }
    // },

    increment() {
      this.quantity += 1; // 增加数量
    },

    decrement() {
      if (this.quantity > 1) {
        this.quantity -= 1; // 减少数量
      }
    },

    viewCart() {
      this.$router.push("/ViewCart");
    }
  }
};
</script>
<style lang="scss" scoped>
.ProDescWrap {
  height: 100vh;
  .footer {
    background: #1c0a00;
    width: 100%;
    .box {
      width: 1200px;
      margin: 0 auto;
      // position: fixed;
      z-index: 99999;
      bottom: 0;
      height: 60px;
      background: #1c0a00;
      .copyRight {
        .copyRightContent {
          text-align: center;
          color: #c3bebc;
          font-size: 16px;
          line-height: 60px;
        }
      }
    }
  }
  ::v-deep .el-table .cell {
    // padding-left: 0 !important;
    // padding-right: 0 !important;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    font-weight: normal;
  }
  ::v-deep .el-table .cell {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .el-table__row:last-child {
    border-bottom: none;
  }
  .Subtotal {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    // margin: 20px;
    border: rgba(0, 0, 0, 0.45);
    position: absolute;
    top: 78px;
    right: 35px;
    z-index: 999;
    span {
      color: rgba(127, 73, 42, 1);
      font-size: 18px;
    }
  }
  .SelectAll {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
    margin: 20px 0;
  }
  ::v-deep .el-checkbox__label {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }
  ::v-deep .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  ::v-deep .el-table th.el-table__cell.is-leaf:last-child {
    border-bottom: none;
  }
  ::v-deep .el-table th.el-table__cell.is-leaf:first-child {
    padding-top: 10px;
  }
  ::v-deep .el-table::before {
    background-color: #fff;
  }
  // ::v-deep .el-table td.el-table__cell,
  // .el-table th.el-table__cell.is-leaf {
  //   border-bottom: rgba(0, 0, 0, 0.45) 1px solid !important;
  // }
  ::v-deep .el-checkbox__inner {
    border-radius: 4px;
  }
  .pro-t-space-line {
    height: 80px;
  }

  .pro-b-space-line {
    height: 100px;
  }
}

.productsDesc {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-top: 50px;

  .decrement {
    cursor: pointer;
    padding: 0 10px;
  }

  .el-button {
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }

  .left-img-wrap {
    margin-right: 48px;
    width: 800px;

    .left-img {
      width: 800px;

      img {
        width: 800px;
        height: 800px;
        margin-top: 16px;
        background: #f3f3f5;
        border-radius: 40px;
      }

      .wscnph:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  .goods-info {
  }

  .title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    // font-weight: bold;
    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .price {
    padding-top: 20px;
    display: flex;
    align-items: center;

    del {
      font-size: 15px;
      color: rgba(0, 0, 0, 0.25);
    }

    .cur-price {
      margin-left: 10px;
      color: rgba(127, 73, 42, 1);
      font-size: 20px;
    }

    .disc-val {
      margin-left: 10px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #d33415 100%;

      .disc-num {
        font-size: 10px;
      }

      .disc-word {
        font-size: 9px;
      }
    }
    .disc-val-new {
      margin-left: 8px;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #b98465;
      color: rgba(255, 255, 255, 1);
      font-size: 10px;
    }
  }

  .right-side-dialog {
    // margin-left: auto; /* 将对话框居右 */
    margin-right: 0;
  }

  .cart {
    // ::v-deep table:first-of-type th:first-child {
    //   /* 样式规则 */
    //   display: none !important;
    // }
    // ::v-deep .el-table__header:first-child {
    //   display: none !important;
    // }
    overflow-y: auto; /* 自动显示垂直滚动条 */
    .lineS {
      width: 400px;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.45);
    }
    ::v-deep .el-table td.el-table__cell,
    .el-table th.el-table__cell.is-leaf:first-chlid {
      border-bottom: rgba(0, 0, 0, 0.45) !important;
    }
    ::v-deep .el-table th.el-table__cell.is-leaf {
      border-bottom: 1px solid rgba(0, 0, 0, 0.45) !important;
    }
    .gdsCommon {
      width: 100%;
      // display: flex;
      // justify-content: space-between;
      img {
        width: 40px;
        height: 24px;
        vertical-align: bottom;
        background-color: #f2f2f4;
      }
      li {
        margin: 0;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
        cursor: pointer;
      }
      li:first-child {
        float: left;
        padding-right: 10px;
      }
      li:last-child {
        float: right;
        padding-right: 14px;
      }
    }
    .goodsName {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
    .goodsImage {
      width: 90px;
      border-radius: 6px;
      background-color: rgba(243, 243, 245, 1);
    }
    .goodsPrice {
      color: rgba(127, 73, 42, 1);
      font-size: 16px;
      padding: 10px 0;
    }
    .QTY {
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      padding: 10px 0;
      padding-right: 14px;
    }
    // border: 1px solid #ccc;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99999;
    width: 400px;
    box-shadow: -6px 0px 26px 0px rgba(0, 0, 0, 0.15);
    background: #fff;
    height: 100vh;
    padding: 20px;
    .cartTitle {
      margin-bottom: 20px;
      div:first-child {
        float: left;
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
      }
      div:last-child {
        float: right;
      }
      .closeCart {
        cursor: pointer;
        width: 16px;
        height: 16px;
        color: rgba(0, 0, 0, 0.85);
        img {
          width: 16px;
        }
      }
    }
    .cartform {
      margin-top: 20px;
    }
  }

  .fontS {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    margin: 10px 0;
  }

  .quantity {
    margin: 10px 0;
    .quantityBtn {
      float: right;
      span {
        padding: 0 10px;
      }
    }
  }

  .sizeS {
    li {
      // background: #f3f3f5;
      padding: 10px 0;
      width: 20%;
      float: left;
      text-align: center;
      border-radius: 4px;
      margin-right: 10px;
      margin-left: 0;
      // margin-top: 10px;
    }
  }

  .gui-ge-title {
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-family: PingFangSC-Medium;
    margin-top: 24px;
  }

  .gui-ge {
    margin-top: 8px;
    display: flex;
  }

  .gui-ge li {
    width: 112px;
    height: 44px;
    border-radius: 4px;
    background-color: rgba(243, 243, 245, 1);
    margin-left: 10px;
    text-align: center;
    line-height: 44px;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.85);
    font-size: 16px;
  }

  .gui-ge li:nth-child(1) {
    margin-left: 0;
  }

  .gui-ge-selected {
    border: 1px solid #b98465;
    cursor: pointer;
  }

  .gui-ge-colour {
    margin-top: 8px;
    display: flex;
    cursor: pointer;
  }

  .gui-ge-colour li {
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: rgba(243, 243, 245, 1);
    margin-left: 10px;
    text-align: center;
    // line-height: 44px;
    cursor: pointer;
    // color: rgba(0, 0, 0, 0.85);
    // font-size: 16px;
  }
  .gui-ge-colour li:nth-child(1) {
    margin-left: 0;
  }
  .gui-ge-selected-colour {
    border: 1px solid #b98465;
    width: 64px;
    height: 64px;
    background: #f3f3f5;
  }

  .btns {
    .button {
      color: #fff;
      background-color: rgba(185, 132, 101, 1);
      border-color: rgba(185, 132, 101, 1);
      font-size: 16px !important;
      margin-top: 20px;
    }
    .shopNow {
      color: rgba(185, 132, 101, 1);
      background-color: #fff;
      border-color: #b98465;
      font-size: 16px !important;
      margin-top: 20px;
    }
  }

  .contact-us {
    display: flex;
    margin-top: 14px;
    align-items: center;
    cursor: pointer;

    img {
      width: 15px;
      height: 16px;
    }

    span {
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }
  }

  .description {
    padding: 20px 0;

    .des-title {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.85);
    }

    p {
      margin-top: 5px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
      line-height: 24px;
    }
  }
  .outer-table .inner-table {
    margin-top: 0 !important; /* 重置外边距 */
    padding-top: 0 !important; /* 重置内边距 */
  }
  ::v-deep .el-table__header-wrapper:first-chlid {
    display: none !important;
  }
  ::v-deep .el-table .el-table__cell {
    // padding-left: 6px;
    // padding-right: 6px;
  }
  ::v-deep .el-table .el-table__cell:first-child {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    // padding-top: 0;
  }
  ::v-deep .el-table-column--selection .cell {
    padding-left: 16px !important;
    // padding-right: 16px !important;
  }
}
</style>

<style lang="scss">
.wscnph {
  width: 800px !important;
  height: 800px !important;
  margin-top: 16px;
  background: #f3f3f5;
  border-radius: 40px;
}

.wscnph:nth-child(1) {
  margin-top: 0;
}
</style>

