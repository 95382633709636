<template>
  <div class="AddressBook">
    <Header/>
    <div class="adr-b-top-space-line"></div>
    <div class="ad-b-wrap">
      <div class="titles">
        <div class="titleBig">{{'地址簿' | formatTrans}}</div>
      </div>
      <div class="line">
        <img src="@/assets/images/login/login.png">
      </div>
      <div>
        <el-button class="addNew" round @click="addNew(0)">{{'新增地址' | formatTrans}}</el-button>
      </div>
      <div v-show="hasAddress == false" class="addressImg">
        <img src="@/assets/images/address/address.png">
        <br>
        <p>{{'您尚未注册您的地址，为了节省时间，请添加您的收货地址。' | formatTrans}}</p>
      </div>
      <div v-show="hasAddress == true" v-for="(item,index) in addressLists" :key="index">
        <div class="addressList">
          <div class="fl">
            <p v-show="item.userAddressDefault == 1">{{'默认地址' | formatTrans }}</p>
            <p v-show="item.userAddressDefault != 1">{{ '地址' | formatTrans }}</p>
            <div>{{(item.firstName + ' ' + item.lastName) | formatTrans}}</div>
            <div>{{item.userAddressAddress | formatTrans}}</div>
            <div>{{item.zipCode}}</div>
            <div>{{item.userAddressPhone}}</div>
          </div>
          <div class="clearb"></div>
          <div class="operation fr">
            <div class>
              <a
                v-show="item.userAddressDefault == 0"
                @click="MakeDefault(item.userAddressId)"
              >{{'存为默认' | formatTrans}}</a>
              <a @click="handleRemove(item.userAddressId)">{{'删除' | formatTrans}}</a>
              <a @click="handleEdit(item)">{{'修改' | formatTrans}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="adr-b-bot-space-line"></div>
    <Footer/>
  </div>
</template>
<script>
import { getAddress, setDefault, deleteAddress, logout } from "@/api/index";
import { getTrans } from "@/api/set.js";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hasAddress: "", //是否有地址
      //密码小眼睛切换状态
      flag: false,
      flagType: "password",
      addressLists: []
    };
  },
  onLoad() {
    this.getAddressList();
  },
  mounted() {
    this.getAddressList();
  },
  methods: {
    // 获取地址列表
    getAddressList() {
      let data = {
        token: sessionStorage.getItem("token")
      };
      getAddress(data).then(res => {
        if (res.code == 1 && res.data) {
          if (res.data.length > 0) {
            this.hasAddress = true;
          }
          this.addressLists = res.data;
        } else if (res.code == 10016) {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },
    //小眼睛切换状态
    getFlag() {
      this.flag = !this.flag;
      this.flagType = this.flag ? "text" : "password"; //text为显示密码；password为隐藏密码
    },
    // 新增地址跳转
    addNew(val) {
      this.$router.push({
        path: "/AddAddress",
        query: {
          flag: val
        }
      });
    },
    // 编辑
    handleEdit(val) {
      this.$router.push({
        name: "AddAddress",
        params: {
          dataS: val,
          flag: 1
        }
      });
    },

    // 设置默认址
    MakeDefault(val) {
      let data = {
        token: sessionStorage.getItem("token"),
        userAddressId: val
      };
      setDefault(data).then(res => {
        if (res.code == 1) {
          this.$message({
            message: getTrans(res.message),
            type: "success",
            duration: 3000,
            customClass: "auto-mes-box"
          });
          this.getAddressList();
        } else if (res.code == 10016) {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });

      // this.$http
      //   .post(
      //     "/test/mall-userms/user/address/setDefault", //接口路径
      //     data //参数
      //   )
      //   .then(res => {
      //     if (res.data.code == 1) {
      //       this.$message({
      //         message: "设为默认地址成功",
      //         type: "success"
      //       });
      //       this.getAddressList();
      //     } else {
      //       this.$message({
      //         message: "设为默认地址失败",
      //         type: "success"
      //       });
      //     }
      //   })
      //   .catch(err => {});
    },

    // 删除地址
    handleRemove(val) {
      let data = {
        token: sessionStorage.getItem("token"),
        userAddressId: val
      };
      deleteAddress(data).then(res => {
        if (res.code == 1) {
          this.getAddressList();
        } else if (res.code == 10016) {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            customClass: "auto-mes-box",
            duration: 3000
          });

          logout({ token: sessionStorage.getItem("token") }).then(res => {
            if (res.code == 1) {
              sessionStorage.removeItem("token");
              localStorage.removeItem("emailId");
              this.$router.replace("/Login");
            }
          });
        } else {
          this.hasAddress = false;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });

      // this.$http
      //   .post(
      //     "/test/mall-userms/user/address/delete", //接口路径
      //     data //参数
      //   )
      //   .then(res => {
      //     if (res.data.code == 1) {
      //       this.$message.success("删除地址成功");
      //       this.getAddressList();
      //     } else {
      //       this.$message.error("删除地址失败");
      //     }
      //   })
      //   .catch(err => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.AddressBook {
  .adr-b-top-space-line {
    height: 80px;
  }

  .adr-b-bot-space-line {
    height: 100px;
  }

  .ad-b-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;
  }

  .addNew {
    width: 15%;
    color: #fff;
    background-color: rgba(185, 132, 101, 1);
    border-color: rgba(185, 132, 101, 1);
    font-size: 16px !important;
    // margin-top: 20px;
  }

  .addressImg {
    text-align: center;
    margin: 50px;

    img {
      width: 10%;
    }

    p {
      padding: 20px;
    }
  }

  .addressList {
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin: 40px 0;
    border-radius: 8px;

    p {
      color: rgba(0, 0, 0, 0.85);
      font-size: 24px;
      padding-bottom: 10px;
    }

    div {
      padding: 5px 0;
      color: rgba(0, 0, 0, 0.85);
      font-size: 16px;
    }

    .operation {
      a {
        text-decoration: underline; /* 确保a标签有下划线 */
        padding: 0 20px;
        // font-weight: bold;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.85);
        font-size: 16px;
      }
    }
  }

  .titles {
    text-align: center;

    .titleBig {
      font-size: 40px;
      color: #333;
      font-weight: 900;
    }

    .titleSmall {
      font-size: 14px;
      color: #666;
      margin: 10px 0;
    }
  }

  .line {
    width: 880px;
    margin: 50px auto;

    img {
      width: 100%;
    }
  }
}
</style>

