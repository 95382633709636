<template>
    <div class="WfRApplyRef">
      <Header/>
      <div class="wfra-top-space-line"></div>
      <div class="wfrar-con">
        <div class="wfrarc-table">
            <div class="goods-img">
                <img src="https://oss.hdy33.com/hdy33/hdy33/2024/7/5/8883c6d4-eb00-fc69-167c-baa521ce4d45编组 16备份 2.png" alt="">
            </div>
            <div class="goods-info">
                <div class="goods-name">Dada Sofa</div>
                <div class="goods-attr">
                    <div>
                        <span>{{ '颜色' | formatTrans }}</span>
                        <el-tooltip content="red" placement="top">
                            <span class="img-wrap">
                                <img src="https://oss.hdy33.com/hdy33/hdy33/2024/7/5/8883c6d4-eb00-fc69-167c-baa521ce4d45编组 16备份 2.png" alt>
                            </span>
                        </el-tooltip>
                    </div>
                    <div>
                        <span>{{ '尺码' | formatTrans }}：</span>
                        <span>{{ "2.8m" | formatTrans }}</span>
                    </div>
                    <div>
                        <span>{{ '数量' | formatTrans }}</span>
                        <span>1</span>
                    </div>
                </div>
                <div class="goods-price">{{ curSymbol }}39.00</div>
            </div>
        </div>
        <div class="ser-type">
            <div class="s-title">
                <span class="left">{{ '服务' | formatTrans }}</span>
                <span class="right">{{ '类型' | formatTrans }}</span>
            </div>
            <div class="ser-type-in">
                <div class="ser-t-left">
                    <div class="fir-line">
                        <span>{{ '退款' | formatTrans }}({{ '无需退货' | formatTrans }})</span>
                    </div>
                    <div class="sec-line" :class="lanHj == 2 ? 'spec-sec' : ''">
                        <span>{{ '未收到货物，或与卖方协商同意不退货而只退款.' | formatTrans  }}</span>
                    </div>
                    <div class="third-line" :class="lanHj == 2 ? 'spec-third' : ''">
                        <span>{{ 'Confirm' | formatTrans }}</span>
                    </div>
                </div>
                <div class="ser-t-right">
                    <div class="fir-line">
                        <span>{{ '退货 并且 退款' | formatTrans }}</span>
                    </div>
                    <div class="sec-line">
                        <span>{{ '收到货物，需要退回收到的货物.' | formatTrans }}</span>
                    </div>
                    <div class="third-line">
                        <span>{{ 'Confirm' | formatTrans }}</span>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <div class="wfra-bot-space-line"></div>
      <Footer/>
    </div>
  </template>
  <script>
  import { globalVari } from "@/utils/variable";
  import { getshopBrand } from "@/api/index";
  import Header from "@/views/layout/Header.vue";
  import Footer from "@/views/layout/Footer.vue";
  import { getTrans } from "@/api/set.js";
  
  export default {
    name: "WfRApplyRef",
    components: {
      Header,
      Footer
    },
    data() {
      return {
        hasDatas: "", //是否有数据
        shopId: globalVari.shopId, // 店铺id
        BrowseBrandList: [],
        lanHj: 1,
        curSymbol: ""
      };
    },

    mounted() {
        this.curSymbol = sessionStorage.getItem('currencySymbol')
        // this.fetchData()
        this.getLanHJ()
    },

    activated() {
        this.curSymbol = sessionStorage.getItem('currencySymbol')
    },

    methods: {
      // 获取品牌店铺
      fetchData() {
        let obj = {
          shopId: this.shopId
        };
        getshopBrand(obj).then(res => {
          if (res.code == 1 && res.data) {
            this.hasDatas = false;
            this.BrowseBrandList = res.data;
          } else {
            this.hasDatas = true;
            this.$message({
              message: getTrans(res.message),
              type: "error",
              duration: 3000,
              customClass: "auto-mes-box"
            });
          }
        });
      },

      getLanHJ() {
        if (sessionStorage.getItem('pcLanguage') == 'en') {
          this.lanHj = 1
        } else if (sessionStorage.getItem('pcLanguage') == 'cn') {
          this.lanHj = 2
        } else if (sessionStorage.getItem('pcLanguage') == 'kor') {
          this.lanHj = 3
        } else {
          this.lanHj = 1
        }
      },

      goProductsDesc() {
        
      }
    }
  }
  </script>
    <style lang="scss" scoped>
  .WfRApplyRef {
    
    .wfra-top-space-line {
        height: 80px;
    }

    .wfra-bot-space-line {
        height: 100px;
    }
  
    .wfrar-con {
      width: 1200px;
      margin: 0 auto;
      margin-top: 54px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 8px;

        .wfrarc-table {
            width: 100%;
            display: flex;
            padding: 24px 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);

            .goods-img {
                width: 110px;
                height: 110px;
                background: rgba(243, 243, 245, 1);
                border-radius: 5px;
                margin-left: 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    width: 90%;
                }
            }

            .goods-info {
                margin-left: 13px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 2px 0;

                .goods-name {
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 18px;
                    font-weight: 550;
                }

                .goods-attr {
                    display: flex;
                    color: rgba(0, 0, 0, 0.85);
                    font-size: 16px;

                    div:nth-child(n) {
                        margin-left: 49px;
                    }

                    div:nth-child(1) {
                        display: flex;
                        align-items: center;
                        margin-left: 0;

                        .img-wrap {
                            width: 40px;
                            height: 24px;
                            background: rgba(243, 243, 245, 1);

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

                .goods-price {
                    color: rgba(127, 73, 42, 1);
                    font-size: 18px;
                }
            }
        }

        .ser-type {
            padding: 24px 24px;

            .s-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 18px;

                .right {
                    margin-left: 10px;
                }
            }

            .ser-type-in {
                margin-top: 24px;
                display: flex;
                justify-content: space-between;

                .ser-t-left , .ser-t-right {
                    width: 569px;
                    height: 350px;
                    border: 1px solid rgba(0, 0, 0, 0.15);
                    border-radius: 4px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;

                    .fir-line {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 18px;
                        font-weight: bold;
                    }

                    .sec-line {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 12px;
                        line-height: 20px;
                        width: 360px;
                        margin: 0 auto;
                        margin-top: 32px;
                    }

                    .spec-sec {
                        margin-top: 42px;
                    }

                    .third-line {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 16px;
                        margin: 0 auto;
                        margin-top: 32px;
                        font-weight: bold;
                        cursor: pointer;
                        width: 61px;

                        span {
                            border-bottom: 1px solid rgba(0, 0, 0, 0.85);
                        }
                    }

                    .spec-third {
                        margin-top: 42px;
                    }
                }
                
                .ser-t-right {
                    .sec-line {
                        margin-top: 42px;
                    }

                    .third-line {
                        margin-top: 42px;
                    }
                }
            }
        }
    }
  }
  </style>
    
    