<template>
  <div class="OrderDetails">
    <Header/>
    <div class="order-re-con">
      <div class="titles">
        <div class="title-point"></div>
        <div class="titleBig">{{'Order Status' | formatTrans}}</div>
      </div>

      <div class="order-status">
        <el-tag
          v-show="this.$route.params.orderStatus =='1'"
          type="warning"
        >{{'Pending Payment'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='3'"
          type="warning"
        >{{'To Be Shipped'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='4'"
          type="warning"
        >{{'Shipped'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='6'"
          type="warning"
        >{{'Confirm Receipt'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='7'"
          type="warning"
        >{{'Cancellation'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='8'"
          type="warning"
        >{{'Refunding'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='9'"
          type="warning"
        >{{'Refunded'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='10'"
          type="warning"
        >{{'To Be Picked'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='12'"
          type="warning"
        >{{'Virtual Order Has Expired'| formatTrans}}</el-tag>
        <el-tag
          v-show="this.$route.params.orderStatus =='16'"
          type="warning"
        >{{'Partial Shipment'| formatTrans}}</el-tag>
        <!-- <div class="first-line">{{'待付款' | formatTrans}}</div> -->
        <div
          class="sec-line"
        >{{ '3 hours, 17 minutes, and 02 seconds left to automatically close the transaction' | formatTrans }}</div>
      </div>

      <div class="titles titles-order">
        <div class="title-point"></div>
        <div class="titleBig">{{'Logistics Information' | formatTrans}}</div>
      </div>
      <div class="order-info order-info-fir">
        <div>
          <span>{{'Shipping Method: Express Delivery'}}</span>
          <span>{{'Logistics Company: Yunda Express'}}</span>
          <span>{{'Waybill Number:433968949735227'}}</span>
        </div>
      </div>

      <div class="titles titles-order">
        <div class="title-point"></div>
        <div class="titleBig">{{'Order Information' | formatTrans}}</div>
      </div>

      <div class="order-info order-info-fir">
        <div class="first-line">{{'Shipping Address' | formatTrans}}</div>
        <div
          class="sec-line"
        >{{ this.$route.params.orderReceiverName | formatTrans}}, {{ this.$route.params.orderReceiverContact | formatTrans}}</div>
        <div class="third-line">{{ this.$route.params.orderReceiverAddress | formatTrans}}</div>
      </div>

      <div class="order-info">
        <div class="first-line">{{'Seller Information' | formatTrans}}</div>
        <div class="sec-line contact-us">
          <a @click="ContactUsFn">{{ 'Contact Us' | formatTrans}}</a>
        </div>
      </div>

      <div class="order-info order-info-third">
        <div class="info-top">
          <div class="first-line">{{'Order Content' | formatTrans}}</div>
          <div class="sec-line">
            <span
              class="fir-span"
            >{{ 'Order Number' | formatTrans}}: {{this.$route.params.paymentNumber}}</span>
            <span
              class="sec-span"
            >{{ 'Order Time' | formatTrans}}: {{this.$route.params.orderCreateTime}}</span>
          </div>
        </div>
        <div class="info-bot">
          .
          <div class="left">
            <img
              src="https://oss.hdy33.com/hdy33/hdy33/2024/7/5/2666c890-281d-1d22-f0f0-cc6125035331%E7%BC%96%E7%BB%84%2016%E5%A4%87%E4%BB%BD%209.png"
              alt
            >
            <div class="name-attr-price">
              <div class="goods-title">{{ 'asoidfhaoidnaskdnhoiasndlkan' | formatTrans}}</div>
              <div class="goods-attr-wrap">
                <span>{{ 'Color' | formatTrans}}: {{ 'White' | formatTrans}}</span>
                <span>{{ 'Size' | formatTrans}}: {{ '2.8m' | formatTrans}}</span>
                <span>{{ 'Quantity' | formatTrans}}: 1</span>
              </div>
              <div class="goods-price">$39</div>
            </div>
          </div>
          <div class="right">
            <div class="sub-total">
              <div class="fir-itme">
                <span class="sub-title">{{ 'Subtotal' | formatTrans}}</span>
                <span class="sub-val">$43.5</span>
              </div>
              <div class="sec-item">
                <span class="freight-val">Freight: $2.00</span>
                <span class="tax-val">Tax: $2.50</span>
              </div>
            </div>
            <div class="operate-btn">
              <u class="cancel-btn">Cancel Order</u>
              <u class="pay-btn">Payment</u>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import { globalVari } from "@/utils/variable";
import { getshopBrand } from "@/api/index";
import Header from "@/views/layout/Header.vue";
import Footer from "@/views/layout/Footer.vue";
import shoppingBgImg from "@/assets/images/shoppingBg.png";
import { getTrans } from "@/api/set.js";

export default {
  name: "OrderDetails",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      hasDatas: "", //是否有数据
      shopId: globalVari.shopId, // 店铺id
      activeNames: ["01"],
      BrowseBrandList: [],
      shoppingBgImg
    };
  },
  computed: {
    openArray() {
      return this.BrowseBrandList.map(o => o.id);
    }
  },
  created() {
    //   this.fetchData();
    console.log("this.$route.params===>", this.$route.params);
  },
  methods: {
    // 获取品牌店铺
    fetchData() {
      let obj = {
        shopId: this.shopId
      };
      getshopBrand(obj).then(res => {
        if (res.code == 1 && res.data) {
          this.hasDatas = false;
          this.BrowseBrandList = res.data;
        } else {
          this.hasDatas = true;
          this.$message({
            message: getTrans(res.message),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      });
    },
    ContactUsFn() {
      this.$router.push("/ContactUs");
    },

    // 立即购买
    shopnow(val) {
      this.val = val;
    },

    goshopping(val) {
      this.val = val;
    },

    Remove(val) {
      this.val = val;
    }
  }
};
</script>
  <style lang="scss" scoped>
.OrderDetails {
  padding: 100px 0;

  .order-re-con {
    width: 1200px;
    margin: 0 auto;
  }

  .titles {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .title-point {
      width: 8px;
      height: 8px;
      background: rgba(0, 0, 0, 0.85);
      border-radius: 50%;
      margin-right: 10px;
    }

    .titleBig {
      font-size: 24px;
      color: rgba(0, 0, 0, 0.85);
      font-family: PingFangSC-Medium;
    }
  }

  .titles-order {
    margin-top: 48px;
  }

  .order-status {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 21px 20px;

    .first-line {
      display: inline-block;
      padding: 3px 8px;
      background: rgba(185, 132, 101, 0.15);
      border-radius: 2px;
      color: rgba(185, 132, 101, 1);
      font-size: 12px;
      font-family: PingFangSC-Regular;
    }

    .sec-line {
      margin-top: 12px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }
  }

  .order-info {
    margin-top: 12px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 21px 20px;

    .first-line {
      display: inline-block;
      font-family: PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
    }

    .sec-line {
      margin-top: 20px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
    }

    .third-line {
      margin-top: 6px;
    }
  }

  .order-info-fir {
    margin-top: 24px;
    span {
      // width: 25%;
      // display: block;
      // float: left;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      padding-right: 20px;
    }
  }

  .order-info-third {
    padding: 0;

    .info-top {
      padding: 21px 20px;
    }

    .sec-line {
      display: flex;
      color: rgba(0, 0, 0, 0.85);
      font-size: 14px;
      .sec-span {
        margin-left: 100px;
      }
    }

    .info-bot {
      border-top: 1px solid rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: start;
      height: 158px;

      .left {
        display: flex;
        align-items: center;
        width: 50%;
        img {
          width: 110px;
        }

        .name-attr-price {
          display: flex;
          flex-direction: column;
          margin-left: 13px;

          .goods-title {
            color: rgba(0, 0, 0, 0.85);
            font-size: 18px;
          }

          .goods-attr-wrap {
            display: flex;
            font-size: 16px;
            margin-top: 16px;
          }

          .goods-price {
            color: rgba(127, 73, 42, 1);
            font-size: 18px;
            margin-top: 16px;
          }
        }
      }

      .right {
        display: flex;
        border-left: 1px solid rgba(0, 0, 0, 0.15);

        .sub-total {
          border-right: 1px solid rgba(0, 0, 0, 0.15);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-left: 24px;
          padding-right: 24px;
          width: 50%;
          .fir-itme {
            display: flex;
            flex-direction: column;
            align-items: center;

            .sub-title {
              font-size: 16px;
            }

            .sub-val {
              color: rgba(127, 73, 42, 1);
              font-size: 18px;
              margin-top: 4px;
            }
          }

          .sec-item {
            display: flex;
            flex-direction: column;
            padding: 8px 9px;
            background: rgba(243, 243, 245, 1);
            text-align: center;
            margin-top: 12px;
            font-size: 12px;

            .tax-val {
              margin-top: 4px;
            }
          }
        }

        .operate-btn {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          padding: 0 36px;

          .pay-btn {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .line {
    margin-top: 10px;
    width: 100%;
    height: 1px;
    background: #999;
  }

  img {
    width: 80%;
  }

  ::v-deep .el-collapse-item__header {
    font-size: 26px;
    padding: 10px 0;
  }

  .itemsS {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
  
  