<template>
  <div class="login">
    <Header/>
    <div class="log-top-space-line">&nbsp;</div>
    <div class="login-wrap">
      <div class="titles">
        <div class="titleBig">{{'请登录或注册' | formatTrans}}</div>
        <div class="titleSmall">{{ `欢迎来到${shopLogoName}世界` }}</div>
      </div>

      <div class="tit-line">
        <img src="@/assets/images/login/login.png" style="width: 100%; ">
      </div>

      <div class="box-wrap">
        <div class="box-in">
          <div class="tabs">
            <div
              v-for="(tab, index) in tabs"
              :key="index"
              :class="{'active': activeIndex === index}"
              @click="activeIndex = index"
            >{{ tab | formatTrans}}</div>
          </div>
          <div class="clearb"></div>
          <div class="tab-content">
            <div v-if="activeIndex === 0">
              <!-- 第一个Tab的内容 -->
              <el-form
                :model="loginForm"
                :rules="lgRules"
                ref="loginForm"
                class="loginForm"
                :key="12345"
              >
                <el-form-item prop="userEmail">
                  {{ '邮箱地址' | formatTrans}}
                  <el-input
                    v-model="loginForm.userEmail"
                    clearable
                    placeholder="E-mail Address"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  {{ '密码' | formatTrans}}
                  <el-input
                    :type="flagType"
                    v-model="loginForm.password"
                    clearable
                    show-password
                    placeholder="Password"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <el-checkbox
                  v-model="loginForm.rememberMe"
                  @change="changeRemem"
                  class="remember"
                >{{ '记住密码' | formatTrans}}</el-checkbox>
                <div class="forgetPassWord" @click="forgetPassWord">{{ '忘记密码' | formatTrans}}?</div>
                <el-form-item style="width:100%;">
                  <el-button
                    style="width:100%;"
                    type="primary"
                    round
                    @click="loginSubmit()"
                    class="button"
                  >{{ '登录' | formatTrans}}</el-button>
                </el-form-item>
              </el-form>
            </div>
            <div v-if="activeIndex === 1">
              <!-- 第二个Tab的内容 -->
              <el-form
                :model="registerForm"
                :rules="reRules"
                ref="registerForm"
                class="registerForm"
                :key="54321"
              >
                <el-form-item prop="userTitle">
                  <div style="display:flex;flex-direction: column;">
                    <label>{{ '称呼' | formatTrans}} *</label>
                    <el-select
                      v-model="registerForm.userTitle"
                      placeholder="please check Title"
                      @change="titleChange"
                    >
                      <el-option
                        v-for="dict in title_list"
                        :key="dict.value"
                        :label="dict.label"
                        :value="dict.label"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item prop="firstName">
                  {{ '名' | formatTrans}} *
                  <el-input
                    v-model="registerForm.firstName"
                    clearable
                    placeholder="Please input firstName"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="lastName">
                  {{ '姓' | formatTrans}} *
                  <el-input
                    v-model="registerForm.lastName"
                    clearable
                    placeholder="Please input lastName"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="country">
                  <div style="display:flex;flex-direction: column;">
                    <label>{{ '国家/地区' | formatTrans}} *</label>
                    <el-select
                      filterable
                      v-model="registerForm.country"
                      placeholder="please check country"
                      @change="countryChange"
                    >
                      <el-option
                        v-for="dict in country_list"
                        :key="dict.value"
                        :label="dict.name_en"
                        :value="dict.name_en"
                      ></el-option>
                    </el-select>
                  </div>
                </el-form-item>
                <el-form-item prop="userEmail">
                  <div class="e-mail-wrap">
                    <div>{{ '邮箱地址' | formatTrans}} *</div>
                    <div class="e-input-wrap">
                      <el-input
                        v-model="registerForm.userEmail"
                        clearable
                        placeholder="E-mail Address"
                      ></el-input>
                      <el-button
                        round
                        @click="getCode"
                        class="code-btn"
                        v-show="!codeHasSend"
                      >{{'获取验证码' | formatTrans}}</el-button>
                      <el-button
                        round
                        class="code-count-btn"
                        v-show="codeHasSend"
                        disabled
                      >{{'重新发送' | formatTrans}}（{{ codeCount }}s）</el-button>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item prop="verCode">
                  {{ '验证码' | formatTrans}} *
                  <el-input
                    v-model="registerForm.verCode"
                    clearable
                    placeholder="Verification code"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="password">
                  {{ '密码' | formatTrans}} *
                  <el-input
                    :type="flagType"
                    v-model="registerForm.password"
                    clearable
                    show-password
                    placeholder="Please input Password"
                  ></el-input>
                  <div class="passwordTips">{{ '请输入8个或更多字符以及至少一个数字。' | formatTrans}}</div>
                </el-form-item>
                <el-form-item prop="confirmPwd">
                  {{ '确认密码' | formatTrans}} *
                  <el-input
                    :type="flagType"
                    v-model="registerForm.confirmPwd"
                    clearable
                    show-password
                    placeholder="Please confrm Password"
                  ></el-input>
                </el-form-item>
                <el-checkbox v-model="registerForm.priPol" class="remember">
                  {{'订阅以接收BABlYINO电子邮件。'}}
                  <br>
                  {{'通过订阅，您同意我们的'}}
                  <a style="text-decoration: underline;">{{'隐私政策'}}</a>
                </el-checkbox>
                <el-form-item style="width:100%;">
                  <el-button
                    style="width:100%;"
                    type="primary"
                    round
                    @click="registerSubmit()"
                    class="button"
                  >Create</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="log-bot-space-line">&nbsp;</div>
    <Footer/>
  </div>
</template>
<script>
import Header from "./views/layout/Header.vue";
import Footer from "./views/layout/Footer.vue";
import country from "@/utils/country.json";
const originList = country;
import CryptoJS from "crypto-js";
import Vue from "vue";
import axios from "axios";
Vue.prototype.$http = axios;
import { login, register, sendRegisCode } from "@/api/index";
import { getTrans } from "@/api/set.js";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },

  data() {
    var checkEmail = (rules, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(
          new Error(getTrans("The email account cannot be empty"))
        );
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(
            new Error(getTrans("The email account format is incorrect"))
          );
        }
      }, 100);
    };

    var validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(getTrans("Please enter password")));
      }
      if (!/^(?=.*\d).{8,}$/.test(value)) {
        callback(
          new Error(
            getTrans(
              "Please enter 8 or more characters and at least one number"
            )
          )
        );
      } else {
        callback();
      }
    };

    var confirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(getTrans("Please enter your password again")));
      } else if (value !== this.registerForm.password) {
        callback(
          new Error(
            getTrans("The confirmed password is inconsistent with the password")
          )
        );
      } else {
        callback();
      }
    };

    return {
      shopLogoName: "",
      title_list: [
        {
          value: 0,
          label: "Mr"
        },
        {
          value: 1,
          label: "Mrs"
        },
        {
          value: 2,
          label: "Ms"
        },
        {
          value: 3,
          label: "Mx"
        }
      ],
      country_list: originList,
      tabs: ["登录", "创建帐户"], // 可以根据需要添加更多的标签
      activeIndex: 0, // 当前激活的Tab索引
      flagType: "password",
      loginForm: {
        userEmail: "",
        password: "",
        loginPlatform: 1,
        rememberMe: false,
        sign: parseInt(new Date().getTime() / 1000) + ""
      },
      registerForm: {
        userTitle: "",
        firstName: "",
        lastName: "",
        country: "",
        userEmail: "",
        verCode: "",
        password: "",
        confirmPwd: "",
        platForm: 1,
        priPol: false
      },
      sendMsgTimer: "",
      codeCount: 0,
      codeHasSend: false,
      lgRules: {
        userEmail: [{ required: true, trigger: "blur", validator: checkEmail }]
      },
      reRules: {
        userEmail: [{ required: true, trigger: "blur", validator: checkEmail }],
        verCode: [
          {
            required: true,
            trigger: "blur",
            message: "Verification code is required"
          }
        ],
        password: [{ trigger: "blur", validator: validatePassword }],
        confirmPwd: [{ trigger: "blur", validator: confirmPassword }]
      }
    };
  },

  mounted() {
    if (localStorage.getItem("emailId")) {
      this.$set(this.loginForm, "userEmail", localStorage.getItem("emailId"));
      this.$set(this.loginForm, "rememberMe", true);
    }
    this.shopLogoName = sessionStorage.getItem("shopLogoName");
  },

  activated() {
    if (localStorage.getItem("emailId")) {
      this.$set(this.loginForm, "userEmail", localStorage.getItem("emailId"));
      this.$set(this.loginForm, "rememberMe", true);
    }
  },

  beforeDestroy() {
    clearInterval(this.sendMsgTimer);
    this.sendMsgTimer = "";
  },

  deactivated() {
    clearInterval(this.sendMsgTimer);
    this.sendMsgTimer = "";
  },

  methods: {
    changeRemem(val) {
      if (val) {
        localStorage.setItem("emailId", this.loginForm.userEmail);
      } else {
        if (localStorage.getItem("emailId")) {
          localStorage.removeItem("emailId");
        }
      }
    },

    getCode() {
      if (this.registerForm.userEmail) {
        let mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
        if (mailReg.test(this.registerForm.userEmail)) {
          if (!this.sendMsgTimer) {
            let timeNum = 60;
            this.codeCount = 60;
            this.codeHasSend = true;
            this.sendMsgTimer = setInterval(() => {
              if (this.codeCount > 0 && this.codeCount <= 60) {
                timeNum--;
                this.codeCount = timeNum;
              } else {
                clearInterval(this.sendMsgTimer);
                this.codeHasSend = false;
                this.sendMsgTimer = "";
              }
            }, 1000);

            let obj = {
              userEmail: this.registerForm.userEmail
            };

            sendRegisCode(obj).then(res => {
              if (res.code == 1) {
                this.$message({
                  message: getTrans("Sent successfully"),
                  type: "success",
                  duration: 3000,
                  customClass: "auto-mes-box"
                });
              } else {
                this.$message({
                  message: getTrans(res.message),
                  type: "error",
                  duration: 3000,
                  customClass: "auto-mes-box"
                });
              }
            });
          }
        } else {
          this.$message({
            message: getTrans("The email account format is incorrect"),
            type: "error",
            duration: 3000,
            customClass: "auto-mes-box"
          });
        }
      } else {
        this.$message({
          message: getTrans("The email account cannot be empty"),
          type: "error",
          duration: 3000,
          customClass: "auto-mes-box"
        });
      }
    },

    // registerForm 表单改变
    titleChange(value) {
      this.registerForm.userTitle = value;
    },

    // 国家改变
    countryChange(value) {
      this.registerForm.country = value;
    },

    handleClick(tab, event) {
      console.log(tab, event);
    },

    // 忘记密码
    forgetPassWord() {
      this.$router.push("/ForPasEmail");
    },

    // 用户注册表单提交
    registerSubmit() {
      if (this.registerForm.priPol) {
        let data = {
          userTitle: this.registerForm.userTitle,
          firstName: this.registerForm.firstName,
          lastName: this.registerForm.lastName,
          country: this.registerForm.country,
          userEmail: this.registerForm.userEmail,
          emailCode: this.registerForm.verCode,
          password: CryptoJS.MD5(this.registerForm.password.trim()).toString(),
          platForm: 1
        };

        this.$refs["registerForm"].validate(valid => {
          if (valid) {
            register(data).then(response => {
              if (response.code == 1) {
                sessionStorage.setItem("token", response.data.token);
                this.$router.push("/MyAccount");

                // this.$router.push({
                //   name: "RegisterSuccess",
                //   params: { userEmail: this.registerForm.userEmail }
                // });
              } else {
                this.$message({
                  message: getTrans(response.message),
                  type: "error",
                  duration: 3000,
                  customClass: "auto-mes-box"
                });
              }
            });
          }
        });
      } else {
        this.$message({
          message: getTrans("Please check the protocol first"),
          type: "error",
          duration: 3000,
          customClass: "auto-mes-box"
        });
      }
    },

    // 用户登录表单提交
    loginSubmit() {
      let data = {
        userEmail: this.loginForm.userEmail,
        password: CryptoJS.MD5(this.loginForm.password.trim()).toString(),
        loginPlatform: this.loginForm.loginPlatform,
        sign: parseInt(new Date().getTime() / 1000) + ""
      };

      this.$refs["loginForm"].validate(valid => {
        if (valid) {
          login(data).then(response => {
            if (response.code == 1) {
              sessionStorage.setItem("token", response.data.token);
              this.$router.push("/");
            } else {
              if (response.code == 11002) {
                this.activeIndex = 1;
                this.$message({
                  message: getTrans(response.message),
                  type: "error",
                  duration: 3000,
                  customClass: "auto-mes-box"
                });
              } else {
                this.$message({
                  message: getTrans(response.message),
                  type: "error",
                  duration: 3000,
                  customClass: "auto-mes-box"
                });
              }
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.login {
  height: 100vh;

  .clearb {
    clear: both;
  }

  .log-top-space-line {
    height: 80px;
  }

  .log-bot-space-line {
    height: 100px;
  }

  .login-wrap {
    width: 1200px;
    margin: 0 auto;
    margin-top: 48px;
  }

  .titles {
    text-align: center;

    .titleBig {
      font-size: 40px;
      color: #333;
      font-weight: 900;
    }

    .titleSmall {
      font-size: 14px;
      color: #666;
      margin: 10px 0;
    }
  }

  .tit-line {
    width: 877px;
    margin: 50px auto;
  }

  .box-wrap {
    width: 100%;
    background: #f3f3f5;

    .box-in {
      width: 426px;
      margin: 20px 0;
      padding: 50px 0;
      font-size: 16px !important;
      margin: 0 auto;

      .tabs div {
        padding-bottom: 10px;
        width: 50%;
        text-align: center;
        float: left;
        color: rgba(0, 0, 0, 0.45);
        font-size: 24px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.45);
        cursor: pointer;
      }

      .tabs div.active {
        color: rgba(0, 0, 0, 0.85);
        font-size: 24px;
        font-weight: bold; /* 示例：加粗当前激活的Tab */
        border-bottom: 6px solid #b98465;
      }

      .tab-content {
        margin-top: 50px;

        .e-input-wrap {
          display: flex;

          .code-btn {
            display: block;
            width: 115px;
            margin-left: 8px;
            color: #fff;
            background-color: #b98465;
            border-color: #b98465;
            padding: 0;
            flex-shrink: 0;
          }

          .code-count-btn {
            display: block;
            width: 115px;
            margin-left: 8px;
            padding: 0;
            flex-shrink: 0;
          }
        }

        ::v-deep .el-dialog__footer {
          text-align: left;
        }
      }

      .tab-content > div {
        display: none; /* 隐藏所有的内容面板 */
        padding: 10px;

        .forgetPassWord {
          float: right;
          margin-bottom: 20px;
          color: rgba(0, 0, 0, 0.85);
          font-size: 16px;
          cursor: pointer;
        }

        ::v-deep .el-form-item__content,
        ::v-deep .el-checkbox__label {
          font-size: 16px !important;
          color: rgba(0, 0, 0, 0.85);
        }

        .remember {
          margin: 0px 0px 25px 0px;
          color: #333;
        }

        .button {
          color: #fff;
          background-color: rgba(185, 132, 101, 1);
          border-color: rgba(185, 132, 101, 1);
          font-size: 16px !important;
          margin-top: 20px;
        }
      }

      .tab-content > div:not(.hidden) {
        display: block; /* 显示当前激活的内容面板 */
      }
    }
  }

  .passwordTips {
    margin-top: 5px;
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    line-height: 20px;
  }
}
</style>

